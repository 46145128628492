import React, { useEffect } from "react";
import { Modal, Button, Form, Input, Checkbox } from "antd";

import "./style.scss";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import { user_interaction } from "../../hooks/usePageView";

// Define your props here
interface UploadVideoModalProps {
  className?: string;
  visible: boolean;
  loading?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  userEmail?: string;
}

const TUploadVideoModal = (props: UploadVideoModalProps) => {
  const { visible, onCancel, onSubmit } = props;

  const _onSubmit = () => {
    user_interaction("info_video", "Subir video");
    onSubmit();
  };
  useEffect(() => {}, []);

  return (
    <div className={` ${props.className}`}>
      <Modal
        onCancel={onCancel}
        open={visible}
        width={800}
        footer={[
          <Button
            key="submit"
            type="primary"
            size="large"
            style={{ borderRadius: "31px" }}
            className=" m-auto d-flex"
            onClick={_onSubmit}
          >
            Subir video
          </Button>,
        ]}
      >
        <div className="UploadVideoModal">
          <div className="d-flex">
            <ReactSVG src={pathServer.PATH_ICONS + "ic_video.svg"} />
            <div className="ml-1 mt-2">
              <h2>Análisis de deepfakes en vídeo de VerifAI</h2>
              <p>
                El análisis de un archivo de vídeo es una{" "}
                <b> funcionalidad premium </b> e implica un coste asociado.
              </p>
            </div>
          </div>

          <h2 className="mt-0">Características del vídeo</h2>
          <p>
            Es necesario que en el vídeo a analizar aparezca solo un rostro
            humano para que nuestros motores sean capaces de determinar si se
            trata de un deep fake.
          </p>
          <h2>¿Cómo se calcula el precio?</h2>
          <p>
            Para calcular el coste, solo tienes que arrastrar o seleccionar el
            archivo y subirlo.
            <br /> VerifAI comprobará el tamaño y te informará del precio para
            que puedas proceder al pago.
          </p>
          <h2>Precio</h2>
          <p>
            El coste del análisis de vídeo es de 0,03€/MB (impuestos incluidos).
          </p>
          <h2>Tamaño y duración</h2>
          <p>
            El tamaño no debe superar los 30MB.
            <br />
            Duración mínima 10 segundos. Duración máxima 2 minutos.
          </p>
          <h2>Formatos de archivo</h2>
          <p>
            HD (hasta 1280x720px) válido para formatos .mov y .mp4
            <br />
            Full HD (hasta 1920x1080px) válido para formato .mp4
          </p>
        </div>
      </Modal>
    </div>
  );
};

TUploadVideoModal.displayName = "TUploadVideoModal";

TUploadVideoModal.defaultProps = {
  className: "",
};

export default TUploadVideoModal;
