import { getResultsV2 } from "../../presentation/components/Result/Constants";

const isObjEmpty = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const updatePath = (params) => {
  if (params) {
    const newPath = `${window.location.pathname}/${params}`;
    // Modificar la URL sin recargar la página completa
    window.history.replaceState({}, "", newPath);
  }

  return;
};

export const unixToDate = (unixTimestamp) => {
  // Multiplica por 1000 porque el tiempo en JavaScript se mide en milisegundos, no en segundos como en Unix
  const date = new Date(unixTimestamp * 1000);

  // Opciones para el formato de fecha
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
  };

  // Formatea la fecha
  //@ts-ignore
  const formattedDate = date.toLocaleString("es", options);

  return formattedDate;
};

export const fileSizeStr = (size) => {
  if (size === 0) return "0 Bytes";
  const k = 1024;
  const dm = 1;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
export const roundToTwoDecimals = (number) => {
  return Math.floor(number * 100) / 100;
};

export const groupByField = (xs, key) => {
  if (xs) {
    return xs.reduce(function (rv, x) {
      const types = x[key].split(",");
      types.forEach((type) => {
        (rv[type] = rv[type] || []).push(x);
      });
      return rv;
    }, {});
  }
  return xs;
};

export const scrollToElementById = (id: string, height = 150) => {
  const element = document.getElementById(id);
  if (element) {
    window.scrollTo({ top: element.offsetTop + height, behavior: "smooth" });
  }
};

/**
 * Revoke buffer url from the storage
 * @param urlImage : image stored in the buffer
 * @returns
 */
export const removeImage = () => {
  const url = localStorage.getItem("processedFile");
  URL.revokeObjectURL(url);
  localStorage.removeItem("processedFile");
  return false;
};

export const msToTime = (duration: any) => {
  if (!duration) {
    return "-";
  }
  let milliseconds = Math.floor((duration % 1000) / 100);
  let seconds: string | number = Math.floor((duration / 1000) % 60);
  let minutes: string | number = Math.floor((duration / (1000 * 60)) % 60);
  let hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
};

export const toChartAI = (data: any) => {
  return {
    numAiDetected: data?.filter((t) => t.status === 2 && t.ai_score >= 66.66)
      .length,
    numNoConclusiveDetected: data?.filter(
      (t) => t.status === 2 && t.ai_score >= 33.33 && t.ai_score < 66.66
    ).length,
    numNoAiDetected: data?.filter((t) => t.status === 2 && t.ai_score < 33.33)
      .length,
  };
};

const LIMIT_FRAMES_CHART = 50;

export const formattedData = (data) => {
  let decimal = data.length < LIMIT_FRAMES_CHART ? 2 : 0;
  return {
    labels: data.map((d) => d?.timestamp?.toFixed(decimal)),
    datasets: [
      {
        label: "",
        data: data.map((d) => d?.ai_score),
        backgroundColor: data.map((d) => {
          const { color } = getResultsV2(d?.ai_score);
          return d.isFrame ? "black" : color;
        }),
      },
    ],
  };
};

export const formattedDataAudio = (data) => {
  return {
    labels: data.map((d) => d?.reference),
    datasets: [
      {
        label: "",
        data: data.map((d) => d?.ai_score),
        // backgroundColor: data.map((d) => {
        //   const { color } = getResultsV2(d?.ai_score);
        //   return color;
        // }),
      },
    ],
  };
};

export const focusVideo = (id, time) => {
  const video = document.getElementById(id);
  if (video) {
    //@ts-ignore
    video.currentTime = time;
    //@ts-ignore
    video.pause();
  }
};

export const getFramesFormatted = (thumbnails, results) => {
  const formattedData = Object.entries(thumbnails).map(
    ([reference, index]) => ({
      reference: Number(reference),
      index,
    })
  );
  if (results.length > 0) {
    const framesAvailable = results.filter((frame) =>
      formattedData.find((data) => data.index === frame.reference)
    );
    return framesAvailable;
  }

  return [];
};
