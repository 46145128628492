import React, { useContext, useEffect, useState } from "react";
import { withLanding } from "../../hocs/withLanding";
import TResult from "../../components/Result";
import { useHistory, useParams } from "react-router-dom";

import { VerifaiContext } from "../../context/verifai/VerifaiProvider";
import { Result, message } from "antd";

import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import { generateIframe, closeIframe } from "../../utilities/Captcha";

import "./styles.scss";
import {
  do_payment,
  page_view,
  user_interaction,
} from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import ErrorPage from "../../components/Error";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { fileSizeStr } from "../../../infraestructure/utils/helpers";
import { TIME_OUT_API_CALL } from "../../configuration/config";
import { container } from "../../../inject_container/injection";
import {
  PAYMENT_PORT,
  PaymentPort,
} from "../../../domain/port/inbound/PaymentPort";
import { STATUS_TASK } from "../../components/DropZone/Constants";

const ErrorResponse = ({ error, data }) => {
  if (!error) return;
  if (error)
    return (
      <ErrorPage
        error={error}
        className="error-page pt-1 max-width"
        status="500"
        title="Error"
        subTitle={error?.message}
      />
    );

  if (data.status === STATUS_TASK.failed)
    return (
      <Result
        className="error-page "
        icon={<ReactSVG src={pathServer.PATH_IMG + "result/error_free.svg"} />}
        title="¡Ups! Algo no funcionó como esperábamos"
        subTitle={
          "No hemos podido procesar el archivo que has subido debido a dificultades técnicas. Por favor, inténtalo de nuevo para que podamos procesarlo correctamente. "
        }
      />
    );
};

const ResultPage = () => {
  const { id, accessToken } = useParams<{ id: string; accessToken: string }>();
  const [messageApi, contextHolder] = message.useMessage();

  const {
    loading,
    data,
    error,
    onCheckResult,
    retry,

    waitingSyncPayment,
  } = useContext(VerifaiContext);
  const [isPaymentProgress, setIsPaymentProgress] = useState(false);
  const history = useHistory();
  const { locale } = useContext(LanguageContext);
  const payloadRequest = container.get<PaymentPort>(PAYMENT_PORT);

  const checkResult = async () => {
    if (id && accessToken) {
      await onCheckResult(id, accessToken);
    }
  };

  const doPayment = async () => {
    try {
      setIsPaymentProgress(true);
      const response = await payloadRequest.doPayment(id);

      const iframe = response?.url;
      user_interaction("video_analysis");
      //@ts-ignore
      do_payment(data.payment_details.euro_per_mb, fileSizeStr(data?.filesize));
      if (iframe) {
        generateIframe(iframe);
      } else {
        //Handle error
        messageApi.error(response.detail);
      }
    } catch (e) {
      setIsPaymentProgress(false);
    }
  };

  useEffect(() => {
    checkResult();
    page_view({ page_type: EVENTS_TGM.home, locale });
  }, []);

  useEffect(() => {
    if (retry > 0) {
      setTimeout(() => {
        checkResult();
      }, TIME_OUT_API_CALL);
    }
  }, [retry]);

  useEffect(() => {
    const eventHandler = (event) => {
      const {
        data: { message },
      } = event;
      if (message?.type === "end") {
        closeIframe();
        if (message.data.result === "error") {
          history.push({
            pathname: ROUTES_GENERAL.HOME_PAGE,
            state: {
              errorPayment:
                "El pago no se ha procesado. Vuelve a intentarlo subiendo el video de nuevo",
            },
          });
          window.history.replaceState({}, "");
        }
      }
    };

    window.addEventListener("message", eventHandler);
    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, []);

  return (
    <div className="result-page ">
      {contextHolder}

      <ErrorResponse error={error} data={data} />
      {!error && (
        <TResult
          id={id}
          className="max-width"
          loading={loading}
          data={data}
          doPayment={doPayment}
          isPaymentProgress={isPaymentProgress}
          waitingSyncPayment={waitingSyncPayment}
        />
      )}
    </div>
  );
};

export default withLanding(ResultPage);
