import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";

import TNavigator from "../../components/Navigator";

import "./styles.scss";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { t } from "../../utilities/Message";

const LegalPage = () => {
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="how-it-works">
      <TNavigator
        page={t("app.Landing.Footer.title1.legal")}
        withSearch={false}
      />
      {locale === "es" ? (
        <div className="max-width how_content ">
          <p className="mt-1">
            Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U., en cumplimiento
            de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la
            informaci&oacute;n y de comercio electr&oacute;nico, le informa de
            que:
          </p>

          <ul>
            {" "}
            <li>
              Su denominaci&oacute;n social es&#8239;Telef&oacute;nica
              Innovaci&oacute;n Digital , S.L.U.&#8239;
            </li>
            <li>Su NIF es B83188953.</li>
            <li>
              Est&aacute; inscrita en el Registro Mercantil de Madrid, al tomo
              42.773, folio 213, inscripci&oacute;n 79, hoja M-296237.
            </li>
            <li>
              Su domicilio social est&aacute; en Ronda de la
              Comunicaci&oacute;n, s/n, Edificio Central, 28050, Madrid
              (Espa&ntilde;a).
            </li>
          </ul>

          <p className="mb-1">Todos los derechos reservados:</p>

          <p className="ml-1 mb-1">
            1. Los derechos de propiedad intelectual de este sitio web, su
            c&oacute;digo fuente, dise&ntilde;o, estructura de
            navegaci&oacute;n, bases de datos y los distintos elementos en
            &eacute;l contenidos son titularidad de&#8239; Telef&oacute;nica
            Innovaci&oacute;n Digital, S.L.U. a quien corresponde el ejercicio
            exclusivo de los derechos de explotaci&oacute;n de los mismos en
            cualquier forma y, en especial, los derechos de reproducci&oacute;n,
            distribuci&oacute;n, comunicaci&oacute;n p&uacute;blica y
            transformaci&oacute;n.
          </p>

          <p className="ml-1 mb-1">
            2. Estas Condiciones Generales regulan el acceso y
            utilizaci&oacute;n de este sitio web que&#8239; Telef&oacute;nica
            Innovaci&oacute;n Digital, S.L.U. pone gratuitamente a
            disposici&oacute;n de los usuarios de Internet. El acceso al mismo
            implica su aceptaci&oacute;n sin reservas. La utilizaci&oacute;n de
            determinados servicios ofrecidos en este sitio se regir&aacute;
            adem&aacute;s por las Condiciones Generales previstas en cada caso,
            las cuales se entender&aacute;n aceptadas por el mero uso de tales
            servicios u a trav&eacute;s de otros mecanismos de aceptaci&oacute;n
            habilitados al efecto.
          </p>

          <p className="ml-1 mb-1">
            3. Se autoriza la visualizaci&oacute;n, impresi&oacute;n y descarga
            parcial del contenido de la p&aacute;gina web s&oacute;lo y
            exclusivamente si concurren las siguientes condiciones:
          </p>

          <p className="ml-2">
            . Que sea compatible con los fines de este sitio web.
          </p>

          <p className="ml-2">
            . Que se realice con el exclusivo &aacute;nimo de obtener la
            informaci&oacute;n contenida para uso personal y privado. Se
            proh&iacute;be expresamente su utilizaci&oacute;n con fines
            comerciales o para su distribuci&oacute;n, comunicaci&oacute;n
            p&uacute;blica, transformaci&oacute;n o descompilaci&oacute;n.
            Tambi&eacute;n se proh&iacute;be expresamente la interacci&oacute;n
            con la p&aacute;gina a trav&eacute;s de agentes automatizados como
            web crawlers o similares, y/o aplicar t&eacute;cnicas de data
            scrapping o similares.
          </p>

          <p className="ml-2">
            . Que ninguno de los contenidos relacionados en esta p&aacute;gina
            web sean modificados de manera alguna.
          </p>

          <p className="ml-2">
            . Que ning&uacute;n gr&aacute;fico, icono o imagen disponible en
            esta p&aacute;gina web sea utilizado, copiado o distribuido
            separadamente del texto o resto de im&aacute;genes que lo
            acompa&ntilde;an.
          </p>

          <p className="ml-1 mb-1">
            4. Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U. se reserva la
            facultad de efectuar, en cualquier momento y sin necesidad de previo
            aviso, modificaciones y actualizaciones de la informaci&oacute;n
            contenida en su p&aacute;gina web, de la configuraci&oacute;n y
            presentaci&oacute;n de &eacute;ste y de las condiciones de acceso.
          </p>

          <p className="ml-1 mb-1">
            5. Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U. no garantiza
            la inexistencia de interrupciones o errores en el acceso al sitio
            web, en su contenido, ni que &eacute;ste se encuentre actualizado,
            aunque desarrollar&aacute; sus mejores esfuerzos para, en su caso,
            evitarlos, subsanarlos o actualizarlos.
          </p>

          <p className="ml-1 mb-1">
            6. Tanto el acceso a este sitio web como el uso que pueda hacerse de
            la informaci&oacute;n contenida en el mismo es de la exclusiva
            responsabilidad de quien lo realiza.&#8239; Telef&oacute;nica
            Innovaci&oacute;n Digital, S.L.U. no responder&aacute; de ninguna
            consecuencia, da&ntilde;o o perjuicio que pudieran derivarse de
            dicho acceso o uso de la informaci&oacute;n.&#8239;T
            Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U. no se hace
            responsable de los posibles errores de seguridad que se puedan
            producir ni de los posibles da&ntilde;os que puedan causarse al
            sistema inform&aacute;tico del usuario (hardware y software), los
            ficheros o documentos almacenados en el mismo, como consecuencia de
            la presencia de virus en el ordenador del usuario utilizado para la
            conexi&oacute;n a los servicios y contenidos de la p&aacute;gina
            web, de un mal funcionamiento del navegador o del uso de versiones
            no actualizadas del mismo.
          </p>

          <p className="ml-1 mb-1">
            7. Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U. no asume
            responsabilidad alguna derivada de la concesi&oacute;n o contenidos
            de los enlaces de terceros a los que se hace referencia en la
            p&aacute;gina web, ni garantiza la ausencia de virus u otros
            elementos en los mismos que puedan producir alteraciones en el
            sistema inform&aacute;tico (hardware y software), los documentos o
            los ficheros del usuario, excluyendo cualquier responsabilidad por
            los da&ntilde;os de cualquier clase causados al usuario por este
            motivo.
          </p>

          <p className="ml-1 mb-1">
            8. Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U. es titular o
            cuenta con las autorizaciones necesarias sobre los derechos de
            propiedad industrial referidos a sus productos y servicios. Respecto
            a las citas de productos y servicios de
            terceros,&#8239;Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U.
            reconoce a favor de sus titulares los correspondientes derechos de
            propiedad industrial e intelectual, no implicando su sola
            menci&oacute;n o aparici&oacute;n en la p&aacute;gina web la
            existencia de derechos o responsabilidad alguna
            de&#8239;Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U. sobre
            los mismos, como tampoco respaldo, patrocinio, recomendaci&oacute;n
            por parte de&#8239;Telef&oacute;nica Innovaci&oacute;n Digital,
            S.L.U.
          </p>

          <p className="ml-1 mb-1">
            9. La utilizaci&oacute;n no autorizada de la informaci&oacute;n
            contenida en esta p&aacute;gina web, su reventa, as&iacute; como la
            lesi&oacute;n de los derechos de Propiedad Intelectual o Industrial
            de&#8239;Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U.,
            dar&aacute; lugar a las responsabilidades legalmente establecidas.
          </p>

          <p className="ml-1 mb-1">
            10. Todo enlace de terceros a esta p&aacute;gina web debe serlo a su
            p&aacute;gina principal o de entrada.
          </p>

          <p className="ml-1 mb-1">
            11. Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U. y el
            usuario, con renuncia expresa a cualquier otro fuero, se someten al
            de los juzgados y tribunales del domicilio del usuario para
            cualquier controversia que pudiera derivarse del acceso a esta
            p&aacute;gina web. En el caso de que el usuario tenga su domicilio
            fuera de Espa&ntilde;a,&#8239;Telef&oacute;nica Innovaci&oacute;n
            Digital, S.L.U. y el usuario se someten, con renuncia expresa a
            cualquier otro fuero, a los juzgados y tribunales de la ciudad de
            Madrid (Espa&ntilde;a)
          </p>
        </div>
      ) : (
        <div className="max-width how_content ">
          <p className="mt-1 mb-1">
            Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U., in compliance
            with Law 34/2002 of 11th of July, about information society services
            and electronic commerce, informs that:
          </p>
          <ul>
            <li>
              {" "}
              The name of the company is&#8239;Telef&oacute;nica
              Innovaci&oacute;n Digital, S.L.U.&#8239;
            </li>
            <li> Its NIF is B83188953.</li>
            <li>
              It is registered in the Registro Mercantil de Madrid, volume
              42.773, page 213, sheet M-296237.
            </li>
            <li>
              Its place of business is in Ronda de la Comunicaci&oacute;n, s/n,
              Edificio Central, 28050, Madrid (Spain).
            </li>
          </ul>

          <p className="mt-1 mb-1">All rights reserved:</p>

          <p className="ml-1 mb-1">
            1. Intellectual property rights of the website, its source code,
            design, navigation layout, databases and different items of content
            are owned by&#8239;Telef&oacute;nica Innovaci&oacute;n Digital,
            S.L.U.&#8239;which has the exclusive rights to use these in any way
            it deems appropriate, and, in particular, rights of reproduction,
            distribution, public communication and transformation.
          </p>

          <p className="ml-1 mb-1">
            2. These general terms and conditions govern access and use of the
            web site which&#8239;Telef&oacute;nica Innovaci&oacute;n Digital,
            S.L.U.&#8239;places at the disposal of internet users free of
            charge. Access to the web site implies unreserved acceptance of the
            aforementioned general terms and conditions. The use of certain
            services offered on this web site shall also be governed by the
            specific conditions stipulated in each case, which shall be
            understood to have been accepted simply by use of such services or
            through other acceptance mechanisms enabled to that effect.
          </p>

          <p className="ml-1 mb-1">
            3. Viewing, printing and partially downloading any of the web site's
            content is authorized solely and exclusively under the following
            conditions:
          </p>

          <p className="ml-2">
            That this be compatible with the purposes of the web site.
          </p>

          <p className="ml-2">
            That the information thus obtained is solely for personal and
            private use. Use of the web site's content for commercial purposes
            or for distribution, public communication, conversion or
            decompilation is expressly prohibited. Interaction with the page
            through automated agents such as web crawlers or similar, and/or the
            use of data scraping techniques or similar, is also expressly
            prohibited.
          </p>

          <p className="ml-2">
            That none of the web site's content be altered in any way.
          </p>

          <p className="ml-2">
            That no chart, icon or image available on the web site be used,
            copied or distributed separately from any accompanying text and
            images.
          </p>

          <p className="ml-1 mb-1">
            4. Telef&oacute;nica Innovaci&oacute;n Digital,
            S.L.U.&#8239;reserves the right, at any time and without prior
            warning, to alter and update the information contained in the web
            site, the configuration and presentation of the site, and the access
            conditions.
          </p>

          <p className="ml-1 mb-1">
            5. Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U.&#8239;cannot
            guarantee that there will be no interruptions or errors in access to
            the web site or in its content, nor that the latter is up-to-date,
            although it will strive to prevent and remedy any such interruptions
            and errors and to update content, if necessary.
          </p>

          <p className="ml-1 mb-1">
            6. Both access to this web site and the use made of the information
            contained therein is the exclusive responsibility of the
            user.&#8239;Telef&oacute;nica Innovaci&oacute;n Digital,
            S.L.U.&#8239;shall not be responsible for any consequence, damage or
            injury that may result from access or use of the
            information.&#8239;Telef&oacute;nica Innovaci&oacute;n Digital,
            S.L.U.&#8239;shall not be responsible for any possible security
            errors which may occur nor for any possible damage to the user's
            computer system (hardware and software), or to the files or
            documents stored therein, caused by the presence of a virus in the
            computer used by the user to connect to the web site's services and
            content, by the malfunctioning of the browser or by the use of
            out-of-date versions of the said browser.
          </p>

          <p className="ml-1 mb-1">
            7. Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U.&#8239;assumes
            no responsibility whatsoever in respect of connection to or the
            content of third-party links contained on the web site, nor does it
            guarantee the absence of viruses or other elements in the said links
            which may cause alterations to the user's computer system (hardware
            and software), documents or files, including responsibility for any
            damages of any kind caused to the user for this reason.
          </p>

          <p className="ml-1 mb-1">
            8. Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U.&#8239;owns or
            has the applicable rights over the industrial property rights
            relating to its products and services, and specifically those
            relating to the &quot;Telef&oacute;nica Innovaci&oacute;n Digital,
            S.L.U.&quot; registered trademark. With regard to third party
            products and services mentioned on the web
            site,&#8239;Telef&oacute;nica Innovaci&oacute;n Digital,
            S.L.U.&#8239;acknowledges the claim of the owners to the
            corresponding industrial and intellectual property rights, their
            mere mention or inclusion in the web site
            according&#8239;Telef&oacute;nica Innovaci&oacute;n Digital,
            S.L.U.&#8239;no rights or responsibility whatsoever in respect of
            the said products and services, nor implying any endorsement,
            sponsorship, or recommendation on the part
            of&#8239;Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U.
          </p>

          <p className="ml-1 mb-1">
            9. Unauthorized use of the information included on this web site,
            its sale to third parties, and any breach of&#8239;Telef&oacute;nica
            Innovaci&oacute;n Digital, S.L.U.'s Intellectual or Industrial
            Property rights shall give rise to the legally established
            responsibilities.
          </p>

          <p className="ml-1 mb-1">
            10. All third-party links to this web site must be to the homepage.
          </p>

          <p className="ml-1 mb-1">
            11. Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U.&#8239;and
            the user, expressly waiving their right of recourse to any other
            jurisdiction, hereby submit to the jurisdiction of the Courts of the
            user's place of residence for the resolution of any dispute that may
            arise in relation with access to this web site. If the user's place
            of residence is outside of Spain,&#8239;Telef&oacute;nica
            Innovaci&oacute;n Digital, S.L.U.&#8239;and the user, expressly
            waiving their right of recourse to any other jurisdiction, hereby
            submit to the jurisdiction of the Courts of Madrid (Spain).
          </p>
        </div>
      )}
    </div>
  );
};

export default withLanding(LegalPage);
