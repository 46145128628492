import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { pathServer } from "../../utilities/Function";
import "./style.scss";

const Error = (props) => {
  const { status, title, subTitle, className } = props;
  return (
    <div className={"error_page " + className}>
      <img
        width={600}
        src={pathServer.PATH_IMG + "error/error500.png"}
        alt=""
      />
      <h2>{title || "404"}</h2>
      <h3>{subTitle || "La página que buscas no existe."}</h3>
      <Button type="primary" size="large" style={{ width: "150px" }}>
        {" "}
        <Link to="/">Go Home</Link>{" "}
      </Button>
    </div>
  );
};

export default Error;
