import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Progress } from "antd";
import "./style.scss";

interface ProgressBarProps {
  className?: string;
}

const TIME_START_SECOND_SERVICE = 0;
const TIME_END_SECOND_SERVICE = 99;

const TIME_MILISECONDS = 200;

const TProgressBar = (props: ProgressBarProps) => {
  const [percent, setPercent] = useState(TIME_START_SECOND_SERVICE);

  useEffect(() => {
    let incrementProgress = TIME_START_SECOND_SERVICE;

    const intervalId = setInterval(() => {
      incrementProgress++;
      setPercent(incrementProgress);

      if (incrementProgress >= TIME_END_SECOND_SERVICE) {
        clearInterval(intervalId);
      }
    }, TIME_MILISECONDS);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={`ProgressBar ${props.className}`}>
      {" "}
      <Progress type="line" percent={percent} status="active" />
    </div>
  );
};

TProgressBar.displayName = "TProgressBar";

TProgressBar.defaultProps = {
  className: "",
};

export default TProgressBar;
