export const HowItWorks = [
  {
    id: 1,
    title: "Sube una imagen, audio o vídeo",
    img: "banner/banner_1.svg",
    description:
      "Arrastra el archivo o adjúntalo desde tu ordenador para analizarlo.",
  },
  {
    id: 2,
    title: "Análisis del contenido",
    img: "banner/banner_2.svg",

    description:
      "Espera unos segundos mientras VerifAI analiza el archivo para detectar una posible creación o manipulación con IA.",
  },
  {
    id: 3,
    title: "Descubre los resultados",
    img: "banner/banner_3.svg",

    description:
      "VerifAI mostrará la probabilidad de que el archivo haya sido creado o manipulado con IA.",
  },
];
