import React from "react";
import { Row, Col, Tag } from "antd";

import { pathServer } from "../../utilities/Function";
import TDropZone from "../DropZone";
import "./style.scss";
import { HowItWorks } from "./Constants";

interface BannerProps {
  className?: string;
  uploadFile?: (params: File) => void;
  loading: boolean;
}

const TBanner = (props: BannerProps) => {
  return (
    <div className={`banner ${props.className} max-padding`}>
      <Row gutter={[16, 16]} className="max-width">
        <Col className="w-100 text-center">
          <p className="banner__subtitle font-medium-2">
            Detecta <b>contenido </b> generado o manipulado <br /> con{" "}
            <b> inteligencia artificial</b>
          </p>
          <div className="mt-1">
            <small className="font-light" style={{ fontSize: "1rem" }}>
              Prueba la versión beta de VerifAI, <br /> la solución ﻿para
              detectar deepfakes
            </small>
          </div>

          <TDropZone loading={props.loading} uploadFile={props.uploadFile} />
        </Col>
      </Row>

      <div className="mt-2" id="container-1">
        <div className="text-center m-autor">
          <Tag
            className="mb-1 font-medium-2"
            color="#AEDDD0"
            style={{
              padding: " 5px 15px",
              color: "#222280",
              borderRadius: "20px",
            }}
          >
            Cómo funciona
          </Tag>
        </div>
        <div className="banner__slider">
          {HowItWorks.map((item) => (
            <div key={item.id} className="banner__how-it-works text-center">
              <img
                src={pathServer.PATH_IMG + item.img}
                width={250}
                alt="test"
              />
              <h3 className="font-medium-1 mb-0 mt-0">{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

TBanner.displayName = "TBanner";

TBanner.defaultProps = {
  className: "",
};

export default TBanner;
