import { ImageResponse } from "../../entities/Photo";

export interface ImagePort {
  analyzeImage(
    request: File,
    algorithm_list: string,
    recaptchaToken?: string
  ): Promise<ImageResponse>;
  checkResult(request: string, accessToken: string): Promise<ImageResponse>;
  getModels(): Promise<any>;
  analyzeText(request: string, algorithm_list: string): Promise<ImageResponse>;
}

export const IMAGE_PORT = "IMAGE_PORT";
