import { ROUTES_GENERAL } from "../../utilities/Constant";

export const MENU_OPTIONS = [
  {
    id: 1,
    title: "Analysis",
    message: "Conoce VerifAI",
    route: ROUTES_GENERAL.HOME_PAGE,
    path: "container-1",
  },
  {
    id: 2,
    title: "How it works",
    message: "Nuestra tecnología",
    route: ROUTES_GENERAL.HOME_PAGE,
    path: "container-2",
    hasScroll: true,
  },
  {
    id: 3,
    title: "Help",
    message: "Ayuda",
    route: ROUTES_GENERAL.HELP,
  },
];
