export const EVENTS_TGM = {
  login: "login",
  home: "home",
  how_it_works: "how-it-works",
  conditions: "condiciones-generales",
  legal: "aviso-legal",
  policy: "politica-privacidad",
  help: "help",
  report: "report",
  report_detail: "report_detail",
  contact: "contact",
};
