import { Result, Button } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { pathServer } from "../../utilities/Function";
import { withLanding } from "../../hocs/withLanding";
import "./styles.scss";
import { ROUTES_GENERAL, ROUTES_GENERAL_ESP } from "../../utilities/Constant";
import Routes from "../../configuration/routes/Routes";

const ErrorPage = () => {
  const history = useHistory();
  return (
    <div className={"error_boundary "}>
      <img
        width={700}
        src={pathServer.PATH_IMG + "error/error400.png"}
        alt=""
      />
      <h2>¡Ups! Error 404</h2>
      <h3>
        Parece que te has perdido o que no hemos localizado lo que estabas
        buscando. <br /> Por favor, revisa la dirección de la página por si
        existe alguna errata.
      </h3>
      <Button
        type="primary"
        size="large"
        className="mt-1"
        onClick={() => history.push(ROUTES_GENERAL.HOME_PAGE)}
      >
        Volver al inicio
      </Button>
    </div>
  );
};

export default withLanding(ErrorPage, true);
