import React, { useContext, useEffect } from "react";
import { VerifaiContext } from "../../context/verifai/VerifaiProvider";

import { withLanding } from "../../hocs/withLanding";

import TBanner from "../../components/Banner";
import THero from "../../components/Hero";
import { t } from "../../utilities/Message";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { message } from "antd";

import { page_view } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

import TCarouselV2 from "../../components/CarouselV2";
import { executeCaptcha, loadCaptchaSource } from "../../utilities/Captcha";

import "./styles.scss";
import { onScrollHeader } from "../../utilities/Function";
import { isMobile } from "../../utilities/Mobile";

const HomePage = () => {
  const { locale } = useContext(LanguageContext);
  const { state } = useLocation();

  const history = useHistory();
  const { uploadData, loading, error, dataModels, setError } =
    useContext(VerifaiContext);
  const [messageApi, contextHolder] = message.useMessage();

  const HeroProps = {
    title: t("app.Landing.Hero.Title"),
    description: t("app.Landing.Hero.Description"),
    image: "images/hero_video.png",
    footer: t("app.Landing.Hero.Footer"),
  };

  const uploadFile = async (params: File) => {
    const content_type = localStorage.getItem("typeFile"); //image,video
    if (content_type) {
      const algorithm_list = dataModels[content_type]
        .map((motor) => motor.id)
        .join(",");

      try {
        const recaptchaToken = ((await executeCaptcha()) || "") as string;
        const response = await uploadData(
          params,
          algorithm_list,
          recaptchaToken
        );

        if (!response.id) {
          setError({
            message: "En estos momentos no tenemos motores disponibles.",
          });
          return;
        }
        if (response.id) {
          history.push(
            ROUTES_GENERAL.RESULT +
              "/" +
              response.id +
              "/" +
              response.guest_token
          );
        }
      } catch (e) {}
    }
    return;
  };

  useEffect(() => {
    //@ts-ignore
    if (state?.errorPayment) {
      messageApi.destroy();
      //@ts-ignore
      messageApi.error(state?.errorPayment);
    }
    if (error) {
      messageApi.destroy();
      messageApi.error(error.message);
    }
  }, [error, messageApi, state]);

  useEffect(() => {
    loadCaptchaSource();
    page_view({ page_type: EVENTS_TGM.home, locale });
  }, [locale]);

  useEffect(() => {
    //@ts-ignore
    if (state?.hasScroll) {
      const container = document.getElementById("container-2");
      window.scrollTo({
        top: container.offsetHeight + 200,
        behavior: "smooth",
      });
    }
  }, [state]);

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener("scroll", onScrollHeader);

      return () => {
        window.removeEventListener("scroll", onScrollHeader);
      };
    }
  }, []);

  return (
    <div className="home-page">
      {contextHolder}
      <TBanner uploadFile={uploadFile} loading={loading} />
      <div id="recaptcha-container"></div>
      <THero {...HeroProps} />
      <TCarouselV2 />
    </div>
  );
};

export default withLanding(HomePage);
