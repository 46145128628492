import React from "react";

import "./style.scss";
import { Button } from "antd";

// Define your props here
interface ButtonLinkProps {
  className?: string;
  name: string;
  onClick?: () => void;
}

const TButtonLink = (props: ButtonLinkProps) => {
  const _onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div className={`ButtonLink ${props.className}`}>
      <Button size="middle" className="border-0" onClick={_onClick}>
        {props.name}
      </Button>
    </div>
  );
};

TButtonLink.displayName = "TButtonLink";

TButtonLink.defaultProps = {
  className: "",
};

export default TButtonLink;
