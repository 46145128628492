import React, { FC, ReactNode } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";

import PhotoReducer from "./Photo/reducer";

type ProviderProps = {
  children: ReactNode;
};

// Combine all your reducers here
const store = createStore(PhotoReducer);

export const ReduxProvider: FC<ProviderProps> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};
