import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "./Routes";
import moengage from "@moengage/web-sdk";
import SEO from "../../components/SEO";
import { configMoengage } from "../config";

function TRouter() {
  useEffect(() => {
    moengage.initialize(configMoengage);
  }, []);

  return (
    <>
      <SEO />
      <Router>
        <Switch>
          {Routes.map((route: any) => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
              exact={route?.exact}
            />
          ))}
        </Switch>
      </Router>
    </>
  );
}

export default TRouter;
