import { baseConfig } from "../configuration/config";

const recaptchaToken = baseConfig.recaptchaToken;

export const generateIframe = (iframeUrl) => {
  const overlay = document.createElement("div");
  overlay.className = "overlay";
  document.body.appendChild(overlay);

  const container = document.createElement("div");
  container.className = "iframe-container";
  document.body.appendChild(container);

  const iframe = document.createElement("iframe");
  iframe.src = iframeUrl;
  iframe.width = "100%";
  iframe.frameBorder = "0";
  iframe.allow = "payment";
  iframe.className = "payment-iframe";

  const existingIframe = document.getElementById("paymentIframe");
  if (existingIframe) {
    existingIframe.remove();
  }

  iframe.id = "paymentIframe";
  container.appendChild(iframe);

  document.body.classList.add("no-scroll");
};

function removeElementsByClass(className) {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }
}

export const loadCaptchaSource = () => {
  const script = document.createElement("script");
  script.src =
    "https://www.google.com/recaptcha/api.js?render=" + recaptchaToken;
  document.body.appendChild(script);
  script.onload = () => {
    const recaptchaBadge = document.querySelector(".grecaptcha-badge");
    const recaptchaContainer = document.getElementById("recaptcha-container");
    if (recaptchaBadge && recaptchaContainer) {
      recaptchaContainer.appendChild(recaptchaBadge);
      //@ts-ignore
      recaptchaBadge.style.visibility = "visible";
      //@ts-ignore
      recaptchaBadge.style.position = "static";
    }
  };
};

export const closeIframe = () => {
  const existingIframe = document.getElementById("paymentIframe");
  if (existingIframe) {
    existingIframe.remove();
    const overlay = document.querySelector(".overlay");
    if (overlay) {
      overlay.remove();
    }

    document.body.classList.remove("no-scroll");

    removeElementsByClass("iframe-container");
  }
};

export const executeCaptcha = async () => {
  const grecaptcha = (window as any).grecaptcha;
  return new Promise((resolve, reject) => {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(recaptchaToken, {
          action: "post_file",
        })
        .then(function (token) {
          resolve(token);
        })
        .catch((error) => {
          console.error("Er ror en la ejecución de reCAPTCHA:", error);
          reject(error);
        });
    });
  });
};
