import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";

import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";

import "./style.scss";

interface NavigatorProps {
  className?: string;
  page: string;
  withSearch?: boolean;
  content?: ReactNode;
  customBack?: () => void;
}

const TNavigator = (props: NavigatorProps) => {
  const history = useHistory();

  const _onClick = () => {
    if (props.customBack) {
      props.customBack();
    } else {
      history.goBack();
    }
  };

  return (
    <div className={`Navigator ${props.className} max-padding`}>
      <div className="Navigator__body">
        {/* <ReactSVG
          className="my-auto d-flex icon-medium"
          src={pathServer.PATH_ICONS + "ic_back.svg"}
        /> */}
        <h3 className="ml-1 my-auto font-bold">{props.page}</h3>
      </div>
      <div className="mt-1 Navigator__content ">{props?.content}</div>
    </div>
  );
};

TNavigator.displayName = "TNavigator";

TNavigator.defaultProps = {
  className: "",
  page: " How it works",
  withSearch: true,
};

export default TNavigator;
