import { injectable } from "inversify";
import { PaymentPort } from "../../domain/port/inbound/PaymentPort";
import { baseConfig } from "../../presentation/configuration/config";

const publicUrl = `${baseConfig.baseUrl}/api/pub`;
const TENANT_ID_GENERAL = baseConfig.baseTenant;

@injectable()
export class PaymentAdapter implements PaymentPort {
  async doPayment(id: string): Promise<any> {
    const response = await fetch(
      publicUrl + "/checkout/payment/" + TENANT_ID_GENERAL + "/" + id,
      {
        method: "GET",
      }
    );
    if (response.status === 200 || response.status === 202) {
      const data = await response.json();
      return data;
    }
    throw new Error("Error check result" + response?.status);
  }
}
