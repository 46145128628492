export default {
  tokenStorage: "token",
  routesStorage: "routes",
  emailRegex:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.(com|pe)$/,
  numberRegex: /^[0-9]*$/,
  phoneRegex: /^9[0-9]{8}$/,
  rucRegex: /^[0-9]{12}$/,
  dniRegex: /^[0-9]{8}$/,
  cciNumberRegex: /^[0-9]{20}$/,
  accountNumberRegex: /^[0-9]{20}$/,
  cardNumberRegex: /^[0][1-9]\d{13,15}$|^[1-9]\d{13,15}$/,
  monthRegex: /^0[1-9]{1}|1[0-9]{1}$/,
  yearRegex: /^[0-9]{4}$/,
  licensePlateRegex: /^[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/,
  policyRegex: /^[0-9]+$/,
  padronRegex: /^[0-9]{3}$/,
  cvvRegex: /^[0][1-9]\d{1,2}$|^[1-9]\d{2,3}$/,
  nameRegex:
    /^[a-zA-ZñÑáéíóúÁÉÍÓÚ]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]{1}[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/,
  addressRegex:
    /^[a-zA-ZñÑáéíóúÁÉÍÓÚ]{1}[a-zA-Z0-9.-ñÑáéíóúÁÉÍÓÚ\s]{4}[a-zA-Z0-9.-ñÑáéíóúÁÉÍÓÚ\s]*$/,
  addressCityRegex:
    /^[a-zA-Z]{1}[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s]{1}[a-zA-Z0-9.-ñÑáéíóúÁÉÍÓÚ\s\s]*$/,
  alphaNumericRegex: /^[a-zA-Z0-9]*$/,
  alphaRegex: /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/,
  decimalNumber: /^([1-9]+(\.\d{0,2})?|[1-9]\.?\d{1,2})$/,
  visaStartRegex: /^4.*/,
  masterCardStartRegex: /^(51|55|2221|2720).*/,
  americanExpressStartRegex: /^(34|37).*/,
  dinnersStartRegex: /^(300|305|36|38).*/,
};

export const colorStatusPending = "#E5AF17";
export const bgStatusPending = "#FCF5DE";
export const colorStatusAsignee = "#0DBF64";
export const bgStatusAsignee = "#D2FFEB";
export const colorStatusFinalize = "#5E636A";
export const bgStatusFinalize = "#E0E0E0";
export const colorStatusCanceled = "#ED4A66";
export const bgStatusCanceled = "#FFE1E6";

//actions
export const remisseAceptRequest = "acceptRemisse";
export const remisseCancelRequest = "cancelRemisse";

//fomatDate
export const formatDatePicker = "DD MMM";
export const formatDateApi = "YYYY-MM-DD"; //2020-12-09

export const ROUTES_GENERAL = {
  HOME_PAGE: "/",
  CONTACT_PAGE: "/contact",
  HOW_WORKS: "/how-it-works",
  HELP: "/help",
  RESULT: "/result",
  LEGAL: "https://www.tu.com/en/pages/legal-notice-1",
  CONDITIONS: "https://www.tu.com/es/pages/terminos-tu",
  CONDITIONS_EN: "https://www.tu.com/en/pages/terminos-tu",
  CONDITIONS_V2: "/condiciones-particulares",
  CONDITIONS_V2_EN: "/special-conditions",
  PRIVACY: "https://www.tu.com/en/pages/privacy-policy-1",
  COOKIES: "https://www.tu.com/en/pages/cookies-policy-1",
  TU_COM: "https://www.tu.com/",
};

export const ROUTES_GENERAL_ESP = {
  LEGAL: "https://www.tu.com/es/pages/aviso-legal",
  PRIVACY: "https://www.tu.com/es/pages/politica-de-privacidad",
  COOKIES: "https://www.tu.com/es/pages/cookies",
};

export const PAYMENT_STATUS = {
  started: 0,
  paying: 1,
  payed: 2,
  notPayed: 3,
  alreadyPayed: 4,
};
