import { container } from "../../inject_container/injection";
import { EVENTS_TGM } from "../utilities/Events";
import { GTMPort, GTM_PORT } from "../../domain/port/inbound/GTMPort";
import { normalizeComponentCopy } from "../utilities/Function";

const BUSINESS_MODEL_DEFAULT = "B2C"; // B2B en el futuro

// Utilidad para normalizar el texto de component_copy

const getBasePath = () => {
  const { pathname, search, hash } = window.location;
  const path = `${window.location.origin}${pathname}${search}${hash}`;
  const previousPagePath = localStorage.getItem("previousPagePath") || "/";

  localStorage.setItem("previousPagePath", path);
  return {
    page_name: path,
    previous_page_path: previousPagePath,
    clean_url: `${window.location.origin}${pathname}`,
    business_model: BUSINESS_MODEL_DEFAULT,
  };
};

/**
 * Custom hook to detect page visits and navigations
 * and send a PageView analytic event when the URL changes.
 */
export const page_view = (props) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  const { page_type, locale = "es" } = props;

  gtm.emitEvent("PageDataLayer", {
    event: "page_view",
    page_type,
    locale: locale === "es" ? "ES" : "EN",
    ...getBasePath(),
  });
};

export const file_uploaded = (props) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  const { page_type, data, name, locale = "es" } = props;

  gtm.emitEvent("PageDataLayer", {
    event: "file_uploaded",
    page_type,
    locale: locale === "es" ? "ES" : "EN",
    ...getBasePath(),
    content_type: data?.deviceSelected,
    file_name: name,
  });
};

export const file_uploaded_v2 = (props) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  const { locale = "es", typeFile } = props;

  gtm.emitEvent("PageDataLayer", {
    event: "file_uploaded",
    page_type: "home",
    locale: locale === "es" ? "ES" : "EN",
    content_type: typeFile,
    component_copy: normalizeComponentCopy("Subir"),
    module_name: "upload_file_home",
    ...getBasePath(),
  });
};

export const user_interaction = (moduleName, component_copy = "") => {
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "user_interaction",
    module_name: moduleName,
    locale: "ES",
    component_copy: normalizeComponentCopy(component_copy),
    ...getBasePath(),
  });
};

export const do_payment = (video_analysis_price, video_analysis_size) => {
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "user_interaction",
    component_copy: normalizeComponentCopy("Pagar"),
    component_type: "primary_button",
    module_name: "video_analysis",
    video_analysis_price,
    video_analysis_size: Math.floor(video_analysis_size),
    content_type: "video",
    locale: "ES",
    ...getBasePath(),
  });
};

export const user_interaction_help = (moduleName) => {
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "user_interaction",
    component_type: "link",
    module_name: "help",
    module_title: moduleName,
    component_copy: normalizeComponentCopy(moduleName),
    locale: "ES",
    ...getBasePath(),
  });
};

export const user_interaction_cta = (type) => {
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "user_interaction",
    component_type: "primary_button",
    component_copy: normalizeComponentCopy("Contáctanos"),
    module_name: type === "cta" ? "sticky_banner" : "how_to_help",
    module_title: "Contáctanos",
    locale: "ES",
    ...getBasePath(),
  });
};

export const popup_view_contact = () => {
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "popup_view",
    module_name: "contact_form_verifai",
    module_title: "Contacta con nosotros",
    locale: "ES",
    ...getBasePath(),
  });
};

export const user_interaction_interest = () => {
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "popup_view",
    component_type: "primary_button",
    component_copy: normalizeComponentCopy("Me interesa"),
    module_name: "upload_file",
    module_title: "Me interesa",
    locale: "ES",
    ...getBasePath(),
  });
};

export const main_navigation = (component_type, component_copy) => {
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "main_navigation",
    component_type, // image|primary_button|link
    component_copy: normalizeComponentCopy(component_copy),
    module_name: "main_navigation",
    locale: "ES",
    ...getBasePath(),
  });
};

export const form_submit_custom = (form_id) => {
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "form_submit_custom",
    component_type: "primary_button",
    component_copy: normalizeComponentCopy("Enviar"),
    form_id,
    locale: "ES",
    page_type: "home",
    ...getBasePath(),
  });
};

export const delete_task = (props) => {
  const gtm = container.get<GTMPort>(GTM_PORT);
  const { page_type, taskId } = props;

  gtm.emitEvent("PageDataLayer", {
    event: "delete_task",
    page_type,
    task_id: taskId,
    ...getBasePath(),
  });
};

export const rating_subtask = (props) => {
  const { page_type, review, subTaskId } = props;
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "rating_subtask",
    page_type,
    subTaskId,
    review,
    ...getBasePath(),
  });
};

export const frame_selected = (props) => {
  const { time, reference, image } = props;
  const gtm = container.get<GTMPort>(GTM_PORT);

  gtm.emitEvent("PageDataLayer", {
    event: "frame_selected",
    page_type: EVENTS_TGM.report_detail,
    motor: "vitt",
    reference,
    time,
    image,
    ...getBasePath(),
  });
};
