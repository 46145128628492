import React from "react";
import { FilesAvailable } from "../../../../../domain/entities/Api";
import { Image } from "antd";
import "./styles.scss";

const MetaData = ({ typeFile, loading }) => {
  const processedFile = localStorage.getItem("processedFile");

  const file = loading ? "loading" : typeFile;
  const IMAGE_DEFAULT = "/images/loading.png";

  switch (file) {
    case FilesAvailable.video:
      return (
        <video controls className="w-100 video" autoPlay>
          <source src={processedFile} type="video/mp4" />
        </video>
      );
    case FilesAvailable.image:
      return (
        <Image
          preview={false}
          alt="deep fake"
          src={processedFile || IMAGE_DEFAULT}
          className="w-100"
          fallback={IMAGE_DEFAULT}
        />
      );
    case FilesAvailable.audio:
      return (
        <audio controls className="w-100">
          <source src={processedFile} type={"audio/mp3"} />
          Your browser does not support the audio element.
        </audio>
      );
    case FilesAvailable.text:
      return <p className="fake"> {processedFile}</p>;
    case "loading":
    default:
      if (typeFile === FilesAvailable.video) {
        return (
          <Image
            preview={false}
            alt="deep fake"
            style={file && { filter: "blur(10px)" }}
            src={IMAGE_DEFAULT}
            className="w-100"
          />
        );
      }
      return (
        //@ts-ignore
        <Image
          preview={false}
          alt="deep fake"
          style={file && { filter: "blur(10px)" }}
          src={processedFile || IMAGE_DEFAULT}
          className="w-100"
        />
      );
  }
};

export default MetaData;
