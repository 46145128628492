import remove from "lodash/remove";

export const ITEMS = [
  {
    id: 1,
    icon: "ic_imagen.svg",
    items: [
      "app.Landing.Banner.Instructions.Image",
      "app.Landing.Banner.Instructions.Image_2",
      "app.Landing.Banner.Instructions.Image_3",
    ],
    title: "app.Landing.Banner.Instructions.Image.Title",
  },

  {
    id: 2,
    icon: "ic_audio.svg",
    items: [
      "app.Landing.Banner.Instructions.Audio",
      "app.Landing.Banner.Instructions.Audio_2",
    ],
    title: "app.Landing.Banner.Instructions.Audio.Title",
  },
  {
    id: 3,
    icon: "ic_video.svg",
    items: ["app.Landing.Banner.Instructions.Video"],
    title: "app.Landing.Banner.Instructions.Video.Title",
    onHandleAction: true,
  },
];

export const STATUS_FILE = {
  finished: "finished",
  rejected: "rejected",
  loading: "loading",
  initial: "initial",
};

export const STATUS_TASK = {
  start: 0,
  running: 1,
  done: 2,
  failed: 3,
  cancelled: 4,
};

/**
 * Format Accept Files
 * Max Size Files
 */
export const RequirementsFile = {
  accept: {
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/mp4": [".mp4"],
    "image/mov": [".mov"],
    "image/mp3": [".mp3"],
    "image/m4a": [".m4a"],
    "image/wav": [".wav"],
    "image/wma": [".wma"],
    "image/flac": [".flac"],
    "image/aac": [".aac"],
  },
  maxSize: 30000000, // 30 MB
  maxFiles: 1,
  multiple: false,
};

export const __onDeleteFile = (path, files) => {
  const newFiles = [...files];
  remove(newFiles, { path });
  return newFiles;
};

export const INITIAL_ERROR = { title: "", description: "" };

export const MESSAGES_DROPZONE = {
  FILE: "app.validation.file",
  TEXT_LENGTH: "app.validation.textLength",
};

export const DRAG_ERROR = {
  FileTooLarge: "file-too-large",
  InvalidType: "file-invalid-type",
};
