import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

const SEO = () => {
  const { locale } = useContext(LanguageContext);

  const meta = {
    en: {
      title: "Cybersecurity, Artificial Intelligence and Deepfake | TU VerifAI",
      description:
        "TU VerifAI detects AI manipulation of content. Technological innovation to check if an image, video or text has been generated by AI.",
    },
    es: {
      title: "Ciberseguridad, Inteligencia Artificial y Deepfake | TU VerifAI",
      description:
        "TU VerifAI detecta la manipulación de contenido con IA. Innovación tecnológica para comprobar si una imagen, vídeo o texto ha sido generado por IA.",
    },
  };

  const { title, description } = meta[locale] || meta.es;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SEO;
