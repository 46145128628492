export const baseConfig = {
  recaptchaToken: process.env.REACT_APP_CAPTCHA,
  baseUrl: process.env.REACT_APP_BASE_URL,
  baseTenant: process.env.REACT_APP_TENANT,
  moengage: process.env.REACT_APP_MOENGAGE,
};

export const TIME_OUT_API_CALL = 4000;

export const configMoengage = {
  app_id: baseConfig.moengage,
  debug_logs: 0,
  cluster: "DC_2",
};

export const featureFlag = {
  lang: process.env.REACT_APP_DISABLED_LANG === "true",
  captcha: process.env.REACT_APP_DISABLED_CAPTCHA === "true",
};

export const langAvailable = {
  English: "en",
  Spanish: "es",
};

export const LANG_DEFAULT = langAvailable.Spanish;

export const MOTOR_DISABLED = "hiya";
