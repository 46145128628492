import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";

import "./styles.scss";
import { Link } from "react-router-dom";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { page_view } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";

const BASE_URL = "https://verifai.tu.com/";

const VERIFAI_MAIL = "autoverifai@telefonica.com";

const ConditionsPage = () => {
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    page_view({ page_type: EVENTS_TGM.conditions, locale });
  }, []);
  return (
    <div className="conditions-page how-it-works mb-2">
      <h1 className="text-center font-semibold mt-2 mb-2">
        {locale === "es" ? "Condiciones Generales" : "General Conditions"}
      </h1>
      {locale === "es" ? (
        <div className="max-width  mb-3 conditions-page-body">
          <p className="mt-1 mb-1 font-bold">
            1. OBJETO DE LAS Condiciones Generales Y SU ACEPTACI&Oacute;N
          </p>
          <p className="ml-1">1.1.Objeto</p>
          <p>
            Las Condiciones Generales descritas a continuaci&oacute;n (en
            adelante, las "Condiciones Generales") regulan las relaciones entre
            Telefónica Innovación Digital, S.L. , empresa con NIF n&ordm;
            B83188953, domicilio social en Ronda de la Comunicación, s/n,
            Edificio Central, 28050, Madrid, e en el Registro Mercantil de
            Madrid, al tomo 42.773, folio 213, inscripción 79, hoja M-296237,
            inscripci&oacute;n 1&ordf; (en adelante, "TELEF&Oacute;NICA") y el
            usuario (en adelante, &ldquo;USUARIO&rdquo;) que accede y usa el
            servicio VerifAI, propiedad de y operado por TELEF&Oacute;NICA, en
            todo lo relativo al acceso y uso (en adelante, el
            &ldquo;Servicio&rdquo; o &ldquo;VerifAI&rdquo;).
          </p>
          <p>1.2. Aceptaci&oacute;n</p>
          <p>
            La aceptaci&oacute;n, sin reservas, de las presentes Condiciones
            Generales es indispensable para el uso del Servicio por parte del
            USUARIO. En tal sentido, el USUARIO manifiesta haber le&iacute;do,
            entendido y aceptado las presentes Condiciones Generales con
            car&aacute;cter previo al acceso y uso del Servicio a trav&eacute;s
            de los mecanismos habilitados al efecto.
          </p>
          <p>
            Estas Condiciones Generales estar&aacute;n disponibles a los USUARIO
            del Servicio, en todo momento a trav&eacute;s de la p&aacute;gina
            web donde se aloje el mismo <b> {BASE_URL} </b> . En el caso de que
            el USUARIO no est&eacute; conforme con estas Condiciones Generales
            debe cesar su uso de VerifAI de forma inmediata.
          </p>
          <p>
            La utilizaci&oacute;n del Servicio se encuentra sometida igualmente
            a cuantos avisos, reglamentos de uso e instrucciones t&eacute;cnicas
            fueran puestos en conocimiento del USUARIO por TELEF&Oacute;NICA,
            por cualquier medio y en cualquier momento, que completan lo
            previsto en estas Condiciones Generales en cuanto no se opongan a
            ellas.
          </p>
          <p className="mt-1 mb-1 font-bold">
            2. DESCRIPCI&Oacute;N DE VERIFAI
          </p>
          <p>
            VerifAI es una soluci&oacute;n desarrollada por TELEF&Oacute;NICA
            que tiene como objetivo detectar si un determinado contenido
            proporcionado por el Usuario fue generado o modificado de alguna
            forma por sistemas de Inteligencia Artificial (&ldquo;IA&rdquo;). De
            este modo, VerifAI permite al Usuario conocer la probabilidad (en un
            porcentaje estimado) de que el contenido consultado se haya generado
            o modificado mediante IA. El resultado se ofrece a t&iacute;tulo
            estimativo. TELEF&Oacute;NICA NO ofrece ninguna garant&iacute;a y no
            proporciona ninguna conformidad sobre la exactitud o fiabilidad del
            resultado.
          </p>
          <p>
            Para utilizar el Servicio, el USUARIO podr&aacute; proporcionar el
            contenido en formato de imagen, video (en adelante, el
            &ldquo;Contenido&rdquo;), sin perjuicio de que TELEF&Oacute;NICA
            pueda modificar o habilitar nuevos formatos de consulta de forma
            discrecional. VerifAI est&aacute; desarrollado sobre licencias open
            source de terceros y bases de datos p&uacute;blicas, entrenadas por
            TELEFÓNICA entre las que se encuentran:
          </p>
          <ul>
            {" "}
            <li>Reddit_eli5 de ELI5 con licencia BSD-License</li>
            <li>Open_qa de WikiQA con licencia PWC Custom</li>
            <li>Wiki_csai de Wikipedia con licencia CC-BY-SA</li>
            <li>Medicine de Medical Dialog</li>
            <li>Finance de FiQA</li>
            <li>AI-image-detector de umm-maybe con licencia CC-BY-4.0</li>
          </ul>
          <p className="mt-1 mb-1 font-bold">
            3. NUESTRO COMPROMISO CON VERIFAI
          </p>
          <p>
            A trav&eacute;s de VerifAI TELEF&Oacute;NICA tiene como
            intenci&oacute;n colaborar con la sociedad proporcionando
            herramientas que permitan a las personas interesadas conocer una
            estimaci&oacute;n sobre si determinada informaci&oacute;n o
            contenido es originalmente desarrollado por una persona o si es
            resultado del uso de Inteligencia Artificial, y as&iacute; ayudar a
            formar una opini&oacute;n m&aacute;s realista acerca de dicho
            contenido e informaci&oacute;n relacionada con &eacute;ste.
          </p>
          <p>
            En el marco de este prop&oacute;sito, TELEF&Oacute;NICA asume frente
            a los Usuarios de VerifAI ciertos compromisos relevantes:
          </p>
          <p>
            i. Minimizamos al m&aacute;ximo el tratamiento de datos personales
            de los USUARIOS, no es necesario que el usuario se registre para
            acceder al Servicio.
          </p>
          <p>ii. No utilizamos cookies en la p&aacute;gina web.</p>
          <p>
            iii. No almacenamos el Contenido y no lo compartiremos con terceros.
            Solo almacenamos una firma digital o hash del Contenido asociado a
            la estimaci&oacute;n que se ha generado, ello con el fin de
            facilitar el acceso a la estimaci&oacute;n calculada para dicho
            Contenido por aquellos otros USUARIOS que usen VerifAI para un
            Contenido ya consultado antes. Un hash es una cadena de texto
            codificada formada por n&uacute;mero y letras, irrepetible, que
            representan inequ&iacute;vocamente el Contenido. El hash no es
            reversible, es decir, el Contenido no se puede obtener a
            trav&eacute;s del hash y, por eso, no almacenamos el Contenido
            consultado.
          </p>
          <p>
            iv. Recordamos que el resultado no es conclusivo, exacto ni fiable,
            es un punto de partida a trav&eacute;s del cual el Usuario puede
            hacer, por su cuenta y riesgo, un an&aacute;lisis m&aacute;s
            profundo acerca del origen del Contenido.
          </p>
          <p>
            v. TELEF&Oacute;NICA proporcionar&aacute; el Servicio conforme a los
            t&eacute;rminos dispuestos en las presentes Condiciones
            Particulares.
          </p>
          <p>
            vi. TELEF&Oacute;NICA destinar&aacute; sus mejores esfuerzos para
            mantener el Servicio operativo permitiendo la continuidad de este en
            condiciones t&eacute;cnicas y de seguridad apropiadas, salvo
            interrupciones temporales por servicios de mantenimiento, problemas
            t&eacute;cnicos o inform&aacute;ticos como ca&iacute;das de internet
            producidas por cualquier causa, ataques inform&aacute;ticos y
            situaciones an&aacute;logas que hagan imposible de forma temporal la
            prestaci&oacute;n del Servicio, o que sean ajenas a
            TELEF&Oacute;NICA. Este se restaurar&aacute; cuando las incidencias
            se hayan solventado.
          </p>
          <p>
            vii. Mantendremos un canal de contacto a trav&eacute;s del cual el
            USUARIO podr&aacute; contactarnos para consultas relacionadas con el
            Servicio y/o acerca del resultado de la Consulta, en caso de que no
            est&eacute; de acuerdo con el mismo y/o tenga dudas al respecto.
            TELEF&Oacute;NICA se reserva el derecho (pero no la
            obligaci&oacute;n) a habilitar mecanismos para que bien
            TELEF&Oacute;NICA, terceros, y/o el propio USUARIO puedan (1)
            solicitar la revisi&oacute;n del resultado para un Contenido
            concreto, as&iacute; como (2) listar firmas digitales o hashes de
            Contenidos sobre los que el Servicio no funcionar&aacute; y sobre
            los que no se devolver&aacute; resultado o estimaci&oacute;n alguna.
            Estos canales de contacto, y los mecanismos indicados, podr&aacute;n
            estar sujetos a Condiciones Generales que deber&aacute;n de ser
            le&iacute;das y aceptadas antes de su utilizaci&oacute;n.
          </p>
          <p className="mt-1 mb-1 font-bold">
            4. TUS COMPROMISOS Y OBLIGACIONES COMO USUARIO DE VERIFAI
          </p>
          <p>El USUARIO se compromete a:</p>
          <p>
            i Hacer un uso del Servicio de una forma correcta, diligente y
            acorde con la ley y con las presentes Condiciones Generales y, en
            particular, a abstenerse de utilizarlo con fines distintos de los
            expresamente permitidos en estas Condiciones Generales, en otras
            condiciones aplicables o del aviso legal de la web de VerifAI.
          </p>
          <p>
            ii Hacerse &uacute;nico responsable del Contenido que consulta a
            trav&eacute;s de VerifAI y de la divulgaci&oacute;n que haga del
            resultado consultado, de conformidad con lo establecido en estas
            Condiciones Generales
          </p>
          <p>
            iii Poner en conocimiento de TELEF&Oacute;NICA aquellas incidencias
            que le impidan o dificulten poder acceder al Servicio. As&iacute;
            como, cualquier incidencia, que el Usuario quiera comunicar a
            TELEF&Oacute;NICA debido al resultado obtenido y/o acerca de un
            Contenido que resulte ser de titularidad del Usuario y que este
            pretenda limitar su consulta amparado en un derecho leg&iacute;timo.
          </p>
          <p>
            iv Cumplir con todo lo establecido en estas Condiciones Generales y
            en la legislaci&oacute;n aplicable.
          </p>
          <p className="mt-1 mb-1 font-bold">
            5. USO CORRECTO DE LOS SERVICIOS
          </p>
          <p>
            El USUARIO se compromete a realizar un uso razonable de las
            funcionalidades y prestaciones que comprende el Servicio.
          </p>
          <p>
            En general, el USUARIO acepta que no violar&aacute; ninguna ley,
            contrato, propiedad intelectual o industrial de TELEF&Oacute;NICA
            y/o de terceros, derechos de imagen y protecci&oacute;n de datos,
            entre otros, durante el uso del Servicio, y declara que es el
            &uacute;nico responsable por el Contenido consultado durante la
            utilizaci&oacute;n del Servicio y por cualquier divulgaci&oacute;n
            que haga de los resultados del Servicio. En l&iacute;nea con lo
            anterior, de forma particular, el USUARIO se compromete a:
          </p>
          <p>
            i No consultar y difundir contenidos que contengan cualquier
            material o informaci&oacute;n que sea ilegal, racista, obscena,
            pornogr&aacute;fica, abusiva, difamatoria, enga&ntilde;osa,
            fraudulenta o de cualquier forma contraria a la moral o al orden
            p&uacute;blico;
          </p>
          <p>
            ii No consultar y difundir contenidos que supongan una
            vulneraci&oacute;n en cualquier forma de los derechos fundamentales
            al honor, a la intimidad personal y familiar o a la propia imagen de
            terceros y, muy especialmente, de los menores de edad;
          </p>
          <p>
            iii No consultar y difundir contenidos que vulneren el secreto de
            las comunicaciones o que supongan una infracci&oacute;n de los
            derechos de propiedad intelectual e industrial o de las normas
            reguladoras de la protecci&oacute;n de datos de car&aacute;cter
            general;
          </p>
          <p>
            iv No usar el Servicio para cualquier prop&oacute;sito ilegal o no
            autorizado, incluida la creaci&oacute;n o exhibici&oacute;n de
            contenido ilegal, como contenido que pueda involucrar
            explotaci&oacute;n sexual infantil, o alentar o promover cualquier
            la violencia y/o actividad que viole las Condiciones Generales;
          </p>
          <p>
            v No reproducir, copiar, distribuir, permitir el acceso del
            p&uacute;blico a trav&eacute;s de cualquier modalidad de
            comunicaci&oacute;n p&uacute;blica, transformar o modificar los
            contenidos protegidos en el Servicio, a menos que se cuente con la
            autorizaci&oacute;n del titular de los correspondientes derechos o
            ello resulte legalmente permitido; ni suprimir, eludir o manipular
            el "copyright" y dem&aacute;s datos identificativos de los derechos
            de los titulares incorporados en los contenidos del Servicio,
            as&iacute; como los dispositivos t&eacute;cnicos de
            protecci&oacute;n, las huellas digitales o cualesquiera mecanismos
            de informaci&oacute;n que pudieren contener los mismos;
          </p>
          <p>
            vi No usar el Servicio de cualquier manera que pueda interferir,
            interrumpir, afectar negativamente o impedir a otros usuarios de
            disfrutar plenamente del Servicio.
          </p>
          <p>vii No distribuir spam mediante la subida de Contenido;</p>
          <p>
            viii No usar el Servicio, incluso mediante la difusi&oacute;n de
            cualquier software o la interacci&oacute;n con cualquier API, de tal
            forma que pueda da&ntilde;ar, deshabilitar, sobrecargar o deteriorar
            el funcionamiento del Servicio de cualquier manera;
          </p>
          <p>
            ix No omitir o ignorar las instrucciones que controlan el acceso al
            Servicio, incluido el intento de eludir cualquier sistema de
            limitaci&oacute;n de velocidad mediante el uso de m&uacute;ltiples
            claves de API, dirigiendo el tr&aacute;fico a trav&eacute;s de
            m&uacute;ltiples direcciones IP u ofuscando de otro modo la fuente
            de tr&aacute;fico que env&iacute;a a TELEF&Oacute;NICA;
          </p>
          <p>
            x No usar cualquier miner&iacute;a de datos, robot, ara&ntilde;a,
            rastreador, raspador, script, extensi&oacute;n del navegador, lector
            fuera de l&iacute;nea u otro medio o interfaz automatizada no
            autorizada por TELEF&Oacute;NICA para acceder al Servicio, extraer
            datos o interferir o modificar la representaci&oacute;n de las
            p&aacute;ginas o funcionalidades del Servicio;
          </p>
          <p>
            xi No realizar ingenier&iacute;a inversa, duplicar, descompilar,
            desensamblar o decodificar cualquier aspecto del Servicio, o hacer
            cualquier cosa que pueda descubrir el c&oacute;digo fuente o eludir
            o eludir las medidas empleadas para prevenir o limitar el acceso a
            cualquier servicio, &aacute;rea o c&oacute;digo del Servicio;
          </p>
          <p>
            xii No vender o revender el Servicio o intentar eludir cualquier
            sistema de tarifas de TELEF&Oacute;NICA, si fuera aplicable;
          </p>
          <p>&nbsp;</p>
          <p>
            No utilizar el Servicio o los datos recopilados del Servicio para
            cualquier actividad publicitaria o de marketing directo (incluidos,
            entre otros, el marketing por correo electr&oacute;nico, el
            marketing por SMS y el telemarketing);
          </p>
          <p>
            El incumplimiento por el USUARIO de cualquiera de estas condiciones
            de uso podr&aacute; llevar aparejada la adopci&oacute;n por
            TELEF&Oacute;NICA de las medidas oportunas amparadas en Derecho y en
            el ejercicio de sus derechos u obligaciones, pudiendo llegar a
            restringir el acceso del USUARIO al Servicio, sin que medie
            posibilidad de indemnizaci&oacute;n alguna por los da&ntilde;os y
            perjuicios causados.
          </p>
          <p>
            El USUARIO ser&aacute; el &uacute;nico responsable por cualquier uso
            del Servicio contrario a lo expuesto en estas Condiciones
            Particulares y/o a la legislaci&oacute;n vigente, respondiendo ante
            TELEF&Oacute;NICA y/o terceros por cualquier tipo de da&ntilde;o
            derivado de un incumplimiento por parte del USUARIO, sin cualquier
            tipo de limitaci&oacute;n. En particular, el USUARIO ser&aacute;
            responsable de cualquier reproducci&oacute;n y posterior
            comunicaci&oacute;n al p&uacute;blico de los contenidos mostrados
            y/o facilitados por el Servicio en cualquier medio y/o formato.
          </p>
          <p className="mt-1 mb-1 font-bold">
            6. INICIO DEL SERVICIO Y DURACI&Oacute;N
          </p>
          <p>
            Las presentes Condiciones Generales entre TELEF&Oacute;NICA y el
            USUARIO deber&aacute;n ser aceptadas por el USUARIO cada vez que
            este desee utilizar el Servicio, siendo aplicables durante el tiempo
            que el USUARIO use el Servicio.
          </p>
          <p>
            En el caso de que el USUARIO no est&eacute; conforme con las
            Condiciones Generales del Servicio NO deber&aacute; usarlo.
          </p>
          <p className="mt-1 mb-1 font-bold">
            7. PROTECCI&Oacute;N DE DATOS DE CAR&Aacute;CTER PERSONAL
          </p>
          <p>
            Para disfrutar del Servicio actualmente no es necesario que el
            USUARIO se registre. La p&aacute;gina web tampoco dispone de
            Cookies.
          </p>
          <p>
            Cualquier tratamiento de datos personales que realice
            TELEF&Oacute;NICA en la prestaci&oacute;n del Servicio, se
            realizar&aacute; conforme establecido en la Pol&iacute;tica de
            Privacidad disponible <Link to={ROUTES_GENERAL.PRIVACY}>aqui </Link>{" "}
            y en cumplimiento de lo establecido en la legislaci&oacute;n
            aplicable.
          </p>
          <p className="mt-1 mb-1 font-bold">
            8. PROPIEDAD INDUSTRIAL E INTELECTUAL
          </p>
          <p>a) De TELEF&Oacute;NICA</p>
          <p>
            El USUARIO acepta que todos los elementos integrados en el Servicio
            de TELEF&Oacute;NICA, incluyendo, sin limitaci&oacute;n, apariencia,
            im&aacute;genes, materiales, logos, textos, archivos y
            dise&ntilde;os, est&aacute;n protegidos por la legislaci&oacute;n
            sobre los Derechos de Propiedad Intelectual e Industrial y que los
            derechos sobre los mismos corresponden bien a TELEF&Oacute;NICA y/o,
            en su caso, a sus afiliadas, colaboradores y/o a sus licenciantes.
          </p>
          <p>
            A este respecto, TELEF&Oacute;NICA y/o, en su caso, sus afiliadas,
            colaboradores y/o licenciantes, conservar&aacute;n la plena
            titularidad de los Derechos de Propiedad Intelectual e industrial
            que les pertenezcan al tiempo de ejecutar las presentes Condiciones
            Generales y les corresponder&aacute;n, igualmente, los Derechos de
            Propiedad Intelectual e industrial relativos a cualquier contenido,
            software, tecnolog&iacute;a y/o documentaci&oacute;n, creados,
            desarrollados, modificados, mejorados o transformados como
            consecuencia de la prestaci&oacute;n del Servicio.
          </p>
          <p>
            El USUARIO no podr&aacute; copiar, imitar o usar, parcial o en su
            totalidad, los elementos sujetos a Derechos de Propiedad Intelectual
            e industrial de TELEF&Oacute;NICA sin su autorizaci&oacute;n previa
            por escrito.
          </p>
          <p>
            El Contenido no se copiar&aacute;, imitar&aacute; o usar&aacute; por
            TELEF&Oacute;NICA. Conforme anteriormente indicado,
            TELEF&Oacute;NICA crear&aacute; un hash acerca del resultado de la
            consulta con el fin de realizar su an&aacute;lisis y de mejorar
            nuestros motores de an&aacute;lisis.
          </p>
          <p>b) Del USUARIO</p>
          <p>
            El USUARIO es el &uacute;nico responsable por el Contenido
            consultado y por la divulgaci&oacute;n que haga del resultado
            obtenido a trav&eacute;s de VerifAI.
          </p>
          <p>
            c) Reclamaciones por infracciones de Derechos de Propiedad
            Intelectual e Industrial
          </p>
          <p>
            El USUARIO ser&aacute; el &uacute;nico responsable por cualquier
            infracci&oacute;n a los derechos de propiedad intelectual y/o
            industrial de TELEF&Oacute;NICA y/o de terceros derivados del
            Contenido consultado, incluida la divulgaci&oacute;n que el Usuario
            haga del resultado, respondiendo el USUARIO integralmente por los
            da&ntilde;os causados. TELEF&Oacute;NICA se exime de cualquier
            responsabilidad en este sentido.
          </p>
          <p className="mt-1 mb-1 font-bold">9. INDEMNIDAD</p>
          <p>
            El USUARIO acepta mantener indemne, defender y eximir de toda
            responsabilidad a TELEF&Oacute;NICA, as&iacute; como a sus
            empleados, consultores, proveedores, accionistas, filiales,
            cesionarios, etc. (en adelante, las "Partes de TELEF&Oacute;NICA"),
            frente a todas las reclamaciones, da&ntilde;os, laudos, sentencias,
            p&eacute;rdidas, responsabilidades, obligaciones, multas, intereses,
            honorarios, gastos (incluidos, entre otros, los honorarios y gastos
            de abogados) y los costes relacionados (incluidos, entre otros, las
            costas judiciales, tasas administrativas, costes de
            liquidaci&oacute;n y cualesquiera otros producidos en el curso de
            perseguir la indemnizaci&oacute;n o a consecuencia de
            activaci&oacute;n de primas de seguro por dicha causa), de todo tipo
            y naturaleza, ya sean conocidos o desconocidos, previstos o
            imprevistos (en adelante, los "Reclamaciones"), incluidos, entre
            otros, da&ntilde;os causados por, surgidos de o relacionados con (a)
            su uso o mal uso del Servicio y su Contenido (b) cualquier
            comentario o acci&oacute;n que realice en relaci&oacute;n con el
            Servicio y/o con el Contenido consultado, (c) su violaci&oacute;n o
            incumplimiento de cualquier cl&aacute;usula
          </p>
          <p>
            de estas Condiciones Generales o la ley aplicable, (d) la
            violaci&oacute;n de los derechos u obligaciones de un tercero, (e)
            negligencia o dolo y/o (f) cualquier otro derecho que no pueda
            limitarse por ley.
          </p>
          <p>
            El USUARIO acepta notificar de inmediato a TELEF&Oacute;NICA la
            existencia de cualquier reclamaci&oacute;n o similar de las citadas
            previamente, y cooperar con las Partes de TELEF&Oacute;NICA en la
            defensa de dichas Reclamaciones. Adem&aacute;s, acepta que las
            Partes de TELEF&Oacute;NICA tendr&aacute;n el control de la defensa
            o liquidaci&oacute;n de cualquier Reclamaci&oacute;n. Esta
            indemnizaci&oacute;n es adicional a cualquier otra
            indemnizaci&oacute;n establecida en un acuerdo escrito entre el
            USUARIO y TELEF&Oacute;NICA.
          </p>
          <p className="mt-1 mb-1 font-bold">10. DESCARGO</p>
          <p>
            Si bien TELEF&Oacute;NICA hace sus mejores esfuerzos para que el
            acceso y uso del Servicio por el USUARIO sean seguros,
            TELEF&Oacute;NICA no puede garantizar que el Servicio, as&iacute;
            como cualquier acci&oacute;n del USUARIO utilizando el Servicio o
            los servidores de los proveedores de servicios de TELEF&Oacute;NICA
            para habilitarlo, est&eacute;n libres de virus u otros componentes
            da&ntilde;inos o maliciosos.
          </p>
          <p>
            En la medida en que la ley lo permita, el USUARIO acepta que en
            ning&uacute;n caso TELEF&Oacute;NICA o sus proveedores de servicios
            y/o licenciatarios ser&aacute;n responsables ante &eacute;l o
            cualquier tercero por (a) cualquier p&eacute;rdida de ganancias o
            cualquier da&ntilde;o indirecto o consecuencial que surja de estas
            Condiciones Generales o el Servicio, productos o sitios y productos
            de terceros; (b) sucesos inevitables debidos a fuerza mayor; (c)
            cualquier da&ntilde;o relacionado con la p&eacute;rdida de ingresos,
            p&eacute;rdida de producci&oacute;n, p&eacute;rdida de beneficios,
            p&eacute;rdida de negocio, p&eacute;rdida de ahorro anticipado,
            p&eacute;rdida de oportunidad, p&eacute;rdida de fondo de comercio o
            p&eacute;rdida de datos, ya sea directa o indirecta, e incluso si
            fuera previsible o si TELEF&Oacute;NICA o sus proveedores de
            servicios hubieran sido advertidos de la posibilidad de dichos
            da&ntilde;os; y/o (d) cualquier otra Reclamaci&oacute;n, demanda o
            da&ntilde;o de cualquier tipo que resulte de o est&eacute;
            relacionado con estas Condiciones Generales que surjan de: (i)
            errores del USUARIO, aun siendo involuntarios o sin mala fe; (ii)
            fallo del servidor o interrupci&oacute;n de los procesos de datos;
            (iii) accesos o usos no autorizados; (iv) cualquier actividad no
            autorizada de terceros, incluido, entre otros, el uso de virus,
            phishing, fuerza bruta u otros medios de ataque contra el Servicio;
            (v) cualquier vulnerabilidad o cualquier tipo de fallo,
            comportamiento anormal del software involucrado o cualquier otro
            problema que tenga p&eacute;rdidas o lesiones como resultado.
          </p>
          <p>
            El Servicio que se oferta cumple con lo dispuesto con el
            ordenamiento jur&iacute;dico espa&ntilde;ol.
          </p>
          <p className="mt-1 mb-1 font-bold">11. CONFORMIDAD DEL SERVICIO</p>
          <p>
            El acceso y uso del Servicio y de los distintos elementos del
            Servicio por el USUARIO se realiza bajo su propia direcci&oacute;n y
            riesgo, siendo el USUARIO el &uacute;nico responsable de cualquier
            da&ntilde;o a su sistema inform&aacute;tico o dispositivo
            m&oacute;vil o p&eacute;rdida de datos que pueda resultar de ello.
          </p>
          <p>
            El USUARIO entiende y acepta que el Servicio se proporciona por
            TELEF&Oacute;NICA al USUARIO tal cual se describe en las presentes
            Condiciones Generales y en la p&aacute;gina web del Servicio. sin
            mayores garant&iacute;as o funcionalidades adicionales a las
            descritas.
          </p>
          <p>
            TELEF&Oacute;NICA no proporciona garant&iacute;a alguna respecto al
            resultado de la Consulta, sin limitaci&oacute;n, en lo relativo a su
            exactitud, fiabilidad, veracidad o utilidad pues, conforme
            mencionado anteriormente, se trata de una estimaci&oacute;n de uso
            de IA en el Contenido proporcionado por el Usuario, de forma que
            este pueda realizar un an&aacute;lisis m&aacute;s exhaustivo por
            cuenta propia acerca del origen del contenido consultado.
          </p>
          <p>
            El USUARIO renuncia a cualquier compensaci&oacute;n por parte de
            TELEF&Oacute;NICA con motivo de que el Servicio: (a) no se
            corresponda con las caracter&iacute;sticas de alguna vista previa
            del Servicio o las
          </p>
          <p>
            declaraciones que sobre el mismo se realicen; (b) no cumpla con la
            descripci&oacute;n y funcionalidades contenidas en estas Condiciones
            Generales o no cumplan con las expectativas del USUARIO; (c) no
            est&eacute; disponible de forma ininterrumpida, oportuna, segura o
            libre de errores; (d) no sea preciso, confiable, completo, legal o
            seguro; (e) no pueda ser usado en ning&uacute;n dispositivo en
            particular ni que est&eacute; disponible en ninguna geograf&iacute;a
            en particular o (f) el resultado de la consulta a VerifAI no sea
            correcto.
          </p>
          <p className="mt-1 mb-1 font-bold">12. CONTENIDO DE TERCEROS</p>
          <p>
            El Servicio puede contener enlaces a p&aacute;ginas web de terceros,
            as&iacute; como mostrar contenido de terceros, que en ning&uacute;n
            caso est&aacute;n bajo el control de TELEF&Oacute;NICA. Si el
            USUARIO accede a este contenido de terceros, el USUARIO
            estar&aacute; sujeto a los t&eacute;rminos que regulen dicho
            contenido. TELEF&Oacute;NICA no se hace responsable de dicho
            contenido ni ofrece ninguna garant&iacute;a sobre el mismo.
          </p>
          <p className="mt-1 mb-1 font-bold">
            13. INTERRUPCI&Oacute;N DEL SERVICIO
          </p>
          <p>
            Las presentes Condiciones Generales se extinguir&aacute;n, con la
            consiguiente interrupci&oacute;n definitiva del Servicio, por las
            causas generales admitidas en Derecho, o por decisi&oacute;n
            unilateral de TELEF&Oacute;NICA, o en caso de incumplimiento del
            USUARIO de los compromisos asumidos y las obligaciones derivadas de
            las presentes Condiciones Generales u otras condiciones aplicables.
          </p>
          <p className="mt-1 mb-1 font-bold">14. CESI&Oacute;N</p>
          <p>
            TELEF&Oacute;NICA podr&aacute; ceder el Servicio, y en consecuencia
            las presentes Condiciones Generales, a cualquier Sociedad del GRUPO
            TELEF&Oacute;NICA que pueda prestar en un futuro el Servicio.
          </p>
          <p>
            El USUARIO no puede transferir ni ceder estas Condiciones
            Particulares ni los derechos y licencias otorgados por
            TELEF&Oacute;NICA bajo las mismas sin el consentimiento de
            TELEF&Oacute;NICA.
          </p>
          <p className="mt-1 mb-1 font-bold">
            15. MODIFICACI&Oacute;N DE LAS Condiciones Generales
          </p>
          <p>
            TELEF&Oacute;NICA realizar&aacute;, mientras lo estime necesario, a
            su solo criterio, cualquier cambio, correcci&oacute;n, mejora o
            modificaci&oacute;n en la informaci&oacute;n respecto al objeto y
            contenido de las presentes Condiciones Generales sin conceder al
            USUARIO derecho alguno de reclamaci&oacute;n o indemnizaci&oacute;n
            o reconocimiento de una obligaci&oacute;n.
          </p>
          <p>
            En el caso de que el USUARIO no acepte los cambios que se hagan a
            las presentes Condiciones Generales, deber&aacute; dejar de utilizar
            el Servicio.
          </p>
          <p>
            No obstante lo anterior, las modificaciones de las Condiciones
            Generales que libremente pueda realizar TELEF&Oacute;NICA no
            tendr&aacute;n efectos retroactivos al uso de VerifAI realizado por
            el USUARIO, a quien le aplicar&aacute; la versi&oacute;n de estas
            Condiciones Generales aceptada en el momento de uso del Servicio.
          </p>
          <p className="mt-1 mb-1 font-bold">16. MISCEL&Aacute;NEO</p>
          <p>
            En el caso de que cualquiera de las cl&aacute;usulas (o parte de
            ellas) de las presentes Condiciones Generales fuera declarada
            contraria a equidad o derecho e invalidada por parte de un
            &oacute;rgano judicial u otra autoridad competente, dicha invalidez
            no afectar&aacute; al resto de las cl&aacute;usulas, que
            permanecer&aacute;n vigentes en todos sus t&eacute;rminos.
          </p>
          <p>
            Las presentes Condiciones Generales y todos los t&eacute;rminos y
            documentaci&oacute;n a los que estas se refieren constituyen la
            totalidad del acuerdo y compromiso entre el USUARIO y
            TELEF&Oacute;NICA en lo que respecta al objeto de estas.
          </p>
          <p>
            El hecho de que TELEF&Oacute;NICA no ejercite alg&uacute;n derecho o
            disposici&oacute;n de estas Condiciones Generales no constituye una
            renuncia a dicho derecho o disposici&oacute;n.
          </p>
          <p>
            Todas las cl&aacute;usulas que, por su naturaleza, deban sobrevivir
            a la terminaci&oacute;n de las Condiciones Generales
            continuar&aacute;n en pleno vigor y efecto despu&eacute;s de
            cualquier terminaci&oacute;n de estas Condiciones Generales por
            parte de TELEF&Oacute;NICA o del USUARIO. La terminaci&oacute;n no
            limitar&aacute; los derechos que TELEF&Oacute;NICA ostente por ley.
          </p>
          <p>
            En caso de contradicci&oacute;n entre las versiones en
            espa&ntilde;ol y otros idiomas de las Condiciones Generales,
            prevalecer&aacute; lo dispuesto en las Condiciones Generales en
            espa&ntilde;ol. En cualquier caso, se entender&aacute; que la lengua
            de formalizaci&oacute;n ser&aacute; siempre el espa&ntilde;ol.
          </p>
          <p className="mt-1 mb-1 font-bold">17. CONTACTO Y RECLAMACIONES</p>
          {/* <p>
            El USUARIO podr&aacute; comunicarse con TELEF&Oacute;NICA a
            trav&eacute;s de la siguiente direcci&oacute;n de correo
            electr&oacute;nico: <p className="font-bold"> {VERIFAI_MAIL}.</p>{" "}
          </p> */}
          <p>
            El USUARIO podr&aacute; enviar cualquier solicitud o
            reclamaci&oacute;n relacionada con el Servicio y/o con el resultado
            de la Consulta, como, por ejemplo, en el caso de que entienda que la
            estimativa proporcionada por VerifAI como resultado de la Consulta
            no es adecuada y/o en el caso de que quiera comunicar cualquier
            derecho propio y/ de tercero acerca del Contenido consultado,
            as&iacute; como solicitar limitaciones al uso de este Contenido
            (propio) por parte de VerifAI.
          </p>
          <p>
            A estos efectos, el soporte y atenci&oacute;n al cliente de los
            USUARIOS se realizar&aacute; online a trav&eacute;s de dicha
            direcci&oacute;n y ser&aacute; atendido a la mayor brevedad, en
            horario laboral de lunes a viernes, siempre que no sea festivo.
          </p>
          <p className="mt-1 mb-1 font-bold">
            18. LEY APLICABLE Y RESOLUCI&Oacute;N DE CONFLICTOS
          </p>
          <p>
            Estas Condiciones Generales se regir&aacute;n por la
            legislaci&oacute;n espa&ntilde;ola. En caso de controversia acerca
            de la interpretaci&oacute;n o ejecuci&oacute;n de estas, las partes
            acuerdan someterse a los Juzgados y Tribunales que les correspondan.
          </p>
          <p>
            Adem&aacute;s, le informamos de que la Comisi&oacute;n Europea
            ofrece una plataforma para la resoluci&oacute;n alternativa de
            conflictos, a la cual el USUARIO puede acceder aqu&iacute;:
            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=ES.
          </p>
        </div>
      ) : (
        <div className="max-width mb-3 conditions-page-body ">
          <p className="mt-1 mb-1 font-bold">
            1. PURPOSE OF THE GENERAL TERMS AND CONDITIONS AND THEIR ACCEPTANCE
          </p>

          <p className="ml-1">1.1.Object</p>

          <p>
            The General Terms and Conditions described below (hereinafter, the
            &quot;General Conditions&quot;) regulate the relations between
            TELEF&Oacute;NICA INNOVACI&Oacute;N DIGITAL, S.L.U., a company with
            NIF n&ordm; B83188953, registered office at Ronda de la
            Comunicaci&oacute;n, s/n, Edificio Central, 28050, Madrid, and
            registered in the Mercantile Registry of Madrid in volume 42,773,
            folio 213, entry 79, page M-296237 (hereinafter,
            &quot;TELEF&Oacute;NICA&quot;) and the user (hereinafter,
            &quot;USER&quot;) who accesses and uses the VerifAI service, owned
            and operated by TELEF&Oacute;NICA, in all matters relating to access
            and use (hereinafter, the &quot;Service&quot; or
            &quot;VerifAI&quot;).
          </p>

          <p>1.2 Acceptance</p>

          <p>
            The acceptance, without reservations, of these General Terms and
            Conditions is essential for the use of the Service by the USER. In
            this sense, the USER declares to have read, understood, and accepted
            these General Terms and Conditions prior to accessing and using the
            Service through the mechanisms enabled for this purpose.
          </p>

          <p>
            These General Terms and Conditions will be available to the USERS of
            the Service, at all times through the website where the Service is
            hosted <b> {BASE_URL}</b>
          </p>

          <p>
            In the event that the USER does not agree with these General
            Conditions, he/she must cease his/her use of VerifAI immediately.
          </p>

          <p>
            The use of the Service is also subject to any notices, regulations
            of use and technical instructions made known to the USER by
            TELEF&Oacute;NICA, by any means and at any time, which complete the
            provisions of these General Conditions insofar as they do not oppose
            them.
          </p>

          <p className="mt-1 mb-1 font-bold">2. DESCRIPTION OF VERIFAI</p>

          <p>
            VerifAI is a solution developed by TELEF&Oacute;NICA that aims to
            detect whether a certain content provided by the User was generated
            or modified in any way by Artificial Intelligence (&quot;AI&quot;)
            systems. In this way, VerifAI allows the User to know the
            probability (in an estimated percentage) that the content consulted
            has been generated or modified by AI. The result is given as an
            estimate. TELEF&Oacute;NICA does not offer any guarantee and does
            not provide any agreement as to the accuracy or reliability of the
            result.
          </p>

          <p>
            To use the Service, the USER may provide the content in image, video
            or text format (hereinafter, the &quot;Content&quot;), without
            prejudice to the fact that TELEF&Oacute;NICA may modify or enable
            new consultation formats at its discretion.
          </p>

          <p>
            VerifAI is developed on third-party open source licenses and public
            databases, trained by TELEF&Oacute;NICA, including:
          </p>

          <ul>
            {" "}
            <li>Reddit_eli5-ELI5 under BSD-License</li>
            <li>Open_qa-WikiQA under PWC Custom license</li>
            <li>Wiki_csai-Wikipedia under CC-BY-SA license</li>
            <li>Medicine of Medical Dialog</li>
            <li>Finance of FiQA</li>
            <li>AI-image-detector-umm-maybe under CC-BY-4.0 license</li>
          </ul>

          <p className="mt-1 mb-1 font-bold">3. OUR COMMITMENT TO VERIFAI</p>

          <p>
            Through VerifAI, TELEF&Oacute;NICA intends to collaborate with
            society by providing tools that allow interested persons to know an
            estimate of whether certain information or content is originally
            developed by a person or if it is the result of the use of
            Artificial Intelligence, and thus help form a more realistic opinion
            about such content and information related to it.
          </p>

          <p>
            Within the framework of this purpose, TELEF&Oacute;NICA assumes
            certain relevant commitments to VerifAI Users:
          </p>

          <p>
            We minimise the processing of USERS' personal data as much as
            possible, it is not necessary for the user to register to access the
            Service.
          </p>

          <p>We do not use cookies on the website.</p>

          <p>
            We do not store the Content and will not share it with third
            parties. We only store a digital signature or hash of the Content
            associated with the estimate that has been generated, in order to
            facilitate access to the estimate calculated for such Content by
            those other USERS who use VerifAI for a Content that has already
            been consulted. A hash is an encoded string of text made up of
            numbers and letters, unrepeatable, that unambiguously represent the
            Content. The hash is not reversible, i.e. the Content cannot be
            obtained through the hash and therefore we do not store the Content
            queried.
          </p>

          <p>
            We remind you that the result is not conclusive, exact or reliable,
            it is a starting point through which the User can make, at their own
            risk, a more in-depth analysis of the origin of the Content.
          </p>

          <p>
            TELEF&Oacute;NICA will provide the Service in accordance with the
            terms set forth in these General Conditions.
          </p>

          <p>
            TELEF&Oacute;NICA will make its best efforts to keep the Service
            operational, allowing it to continue under appropriate technical and
            security conditions, except for temporary interruptions due to
            maintenance services, technical or computer problems such as
            internet outages caused by any cause, computer attacks and similar
            situations that make it temporarily impossible to provide the
            Service, or that are unrelated to TELEF&Oacute;NICA. This will be
            restored when the issues have been resolved.
          </p>

          <p>
            We will maintain a contact channel through which the USER can
            contact us for queries related to the Service and/or about the
            result of the Consultation, in case they do not agree with it and/or
            have doubts about it. TELEF&Oacute;NICA reserves the right (but not
            the obligation) to enable mechanisms so that either
            TELEF&Oacute;NICA, third parties, and/or the USER himself may (1)
            request the review of the result for a specific Content, as well as
            (2) list digital signatures or hashes of Content on which the
            Service will not work and on which no result or estimate will be
            returned. These contact channels, and the mechanisms indicated, may
            be subject to particular conditions that must be read and accepted
            before use.
          </p>

          <p className="mt-1 mb-1 font-bold">
            4. YOUR COMMITMENTS AND OBLIGATIONS AS A VERIFAI USER
          </p>

          <p>The USER undertakes to:</p>

          <p>
            Make use of the Service in a correct, diligent manner and in
            accordance with the law and with these General Conditions and, in
            particular, to refrain from using it for purposes other than those
            expressly permitted in these General Conditions, in other applicable
            conditions or in the legal notice of the VerifAI website.
          </p>

          <p>
            Be solely responsible for the Content you consult through VerifAI
            and for the disclosure you make of the result consulted, in
            accordance with the provisions of these General Terms and Conditions
          </p>

          <p>
            Inform TELEF&Oacute;NICA of any incidents that prevent or make it
            difficult for you to access the Service. As well as, any incident,
            that the User wishes to communicate to TELEF&Oacute;NICA due to the
            result obtained and/or about a Content that turns out to be owned by
            the User and that the User intends to limit its consultation
            protected by a legitimate right.
          </p>

          <p>
            Comply with all the provisions of these General Terms and Conditions
            and applicable legislation.
          </p>

          <p className="mt-1 mb-1 font-bold">5. PROPER USE OF THE SERVICES</p>

          <p>
            The USER undertakes to make reasonable use of the functionalities
            and features of the Service.
          </p>

          <p>
            In general, the USER accepts that he/she will not violate any law,
            contract, intellectual or industrial property of TELEF&Oacute;NICA
            and/or third parties, image rights and data protection, among
            others, during the use of the Service, and declares that he/she is
            solely responsible for the Content consulted during the use of the
            Service and for any disclosure he/she makes of the results of the
            Service. In line with the above, in particular, the USER undertakes
            to:
          </p>

          <p>
            Not to consult and disseminate content that contains any material or
            information that is illegal, racist, obscene, pornographic, abusive,
            defamatory, misleading, fraudulent or in any way contrary to
            morality or public order;
          </p>

          <p>
            Not to consult and disseminate content that violates in any way the
            fundamental rights to honour, personal and family privacy or to
            one's own image of third parties and, especially, of minors;
          </p>

          <p>
            Not to consult and disseminate content that violates the secrecy of
            communications or that infringes intellectual and industrial
            property rights or general data protection regulations;
          </p>

          <p>
            Not to use the Service for any illegal or unauthorized purpose,
            including creating or displaying illegal content, such as content
            that may involve child sexual exploitation, or encouraging or
            promoting any violence and/or activity that violates the Terms and
            Conditions;
          </p>

          <p>
            Not to reproduce, copy, distribute, allow public access through any
            form of public communication, transform or modify the protected
            content in the Service, unless you have the authorization of the
            owner of the corresponding rights or it is legally permitted; or
            delete, elude or manipulate the copyright and other identifying data
            of the rights of the owners incorporated in the contents of the
            Service, as well as the technical protection devices, digital
            fingerprints or any information mechanisms that may be contained
            therein;
          </p>

          <p>
            Not to use the Service in any manner that could interfere with,
            disrupt, adversely affect, or prevent other users from fully
            enjoying the Service.
          </p>

          <p>Do not distribute spam by uploading Content;</p>

          <p>
            Not to use the Service, including by broadcasting any software or
            interacting with any API, in any manner that could damage, disable,
            overburden, or impair the operation of the Service in any way;
          </p>

          <p>
            Omit or ignore instructions that control access to the Service,
            including attempting to circumvent any rate-limiting system by using
            multiple API keys, directing traffic through multiple IP addresses,
            or otherwise obfuscating the source of traffic you send to
            TELEFONICA;
          </p>

          <p>
            Not to use any data mining, robot, spider, crawler, scraper, script,
            browser extension, offline reader, or other automated means or
            interface not authorized by TELEFONICA to access the Service,
            extract data, or interfere with or modify the rendering of the pages
            or functionalities of the Service;
          </p>

          <p>
            Not to reverse engineer, duplicate, decompile, disassemble, or
            decode any aspect of the Service, or do anything that could discover
            the source code or circumvent or circumvent measures employed to
            prevent or limit access to any service, area, or code of the
            Service;
          </p>

          <p>
            Not to sell or resell the Service or attempt to circumvent any
            TELEFONICA fee system, if applicable;
          </p>

          <p>
            Not to use the Service or data collected from the Service for any
            advertising or direct marketing activities (including, but not
            limited to, email marketing, SMS marketing, and telemarketing);
          </p>

          <p>
            Failure by the USER to comply with any of these terms of use may
            lead to the adoption by TELEF&Oacute;NICA of the appropriate
            measures protected by law and in the exercise of its rights or
            obligations, which may restrict the USER's access to the Service,
            without the possibility of any compensation for the damages caused.
          </p>

          <p>
            The USER shall be solely responsible for any use of the Service
            contrary to the provisions of these General Conditions and/or
            current legislation, and shall be liable to TELEF&Oacute;NICA and/or
            third parties for any type of damage arising from a breach by the
            USER, without any type of limitation. In particular, the USER will
            be responsible for any reproduction and subsequent communication to
            the public of the content displayed and/or provided by the Service
            in any medium and/or format.
          </p>

          <p>START OF SERVICE AND DURATION</p>

          <p>
            These General Terms and Conditions between TELEF&Oacute;NICA and the
            USER must be accepted by the USER each time the USER wishes to use
            the Service, and are applicable for as long as the USER uses the
            Service.
          </p>

          <p>
            In the event that the USER does not agree with the General Terms and
            Conditions of Service, he/she must NOT use it.
          </p>

          <p className="mt-1 mb-1 font-bold">7. PROTECTION OF PERSONAL DATA</p>

          <p>
            In order to enjoy the Service, it is not currently necessary for the
            USER to register. The website also does not have cookies.
          </p>

          <p>
            Any processing of personal data carried out by TELEF&Oacute;NICA in
            the provision of the Service will be carried out in accordance with
            the provisions of the Privacy Policy available at [include link to
            the PP] and in compliance with the provisions of the applicable
            legislation.
          </p>

          <p className="mt-1 mb-1 font-bold">
            8. INDUSTRIAL AND INTELLECTUAL PROPERTY
          </p>

          <p>By TELEF&Oacute;NICA</p>

          <p>
            The USER accepts that all the elements integrated in the
            TELEF&Oacute;NICA Service, including, without limitation,
            appearance, images, materials, logos, texts, files and designs, are
            protected by the legislation on Intellectual and Industrial Property
            Rights and that the rights over them correspond either to
            TELEF&Oacute;NICA and/or, where appropriate, to its affiliates.
            collaborators and/or their licensors.
          </p>

          <p>
            In this regard, TELEF&Oacute;NICA and/or, as the case may be, its
            affiliates, collaborators and/or licensors, will retain full
            ownership of the Intellectual and Industrial Property Rights that
            belong to them at the time of execution of these General Conditions
            and will also correspond to the Intellectual and industrial Property
            Rights related to any content, software, technology and/or
            documentation. created, developed, modified, improved or transformed
            as a result of the provision of the Service.
          </p>

          <p>
            The USER may not copy, imitate, or use, in whole or in part, the
            elements subject to TELEF&Oacute;NICA's Intellectual and Industrial
            Property Rights without their prior written authorisation.
          </p>

          <p>
            The Content will not be copied, imitated, or used by
            TELEF&Oacute;NICA. As indicated above, TELEF&Oacute;NICA will create
            a hash about the result of the query in order to perform its
            analysis and improve our analysis engines.
          </p>

          <p>From the USER</p>

          <p>The USER is solely responsible for the Content consulted.</p>

          <p>
            Claims for infringement of Intellectual and Industrial Property
            Rights
          </p>

          <p>
            The USER shall be solely responsible for any infringement of the
            intellectual and/or industrial property rights of TELEF&Oacute;NICA
            and/or third parties arising from the Content consulted, including
            the disclosure by the User of the result, and the USER shall be
            fully liable for the damages caused. TELEF&Oacute;NICA disclaims any
            liability in this regard.
          </p>

          <p className="mt-1 mb-1 font-bold">9. INDEMNITY</p>

          <p>
            The USER agrees to indemnify, defend and hold harmless
            TELEF&Oacute;NICA, as well as its employees, consultants, suppliers,
            shareholders, affiliates, assigns, etc. (hereinafter referred to as
            the &quot;TELEF&Oacute;NICA Parties&quot;), from and against all
            claims, damages, awards, judgments, losses, liabilities,
            obligations, fines, interest, fees, expenses (including, but not
            limited to, attorneys' fees and expenses) and related costs
            (including, among others, legal costs, administrative fees,
            settlement costs and any other costs incurred in the course of
            pursuing compensation or as a result of the activation of insurance
            premiums for such reason), of all types and nature, whether known or
            unknown, foreseen or unforeseen (hereinafter, the
            &quot;Claims&quot;). including, but not limited to, damages caused
            by, arising out of, or related to (a) your use or misuse of the
            Service and its Content (b) any comments or actions you take in
            connection with the Service and/or the Content viewed, (c) your
            violation or failure to comply with any provision of these Terms or
            applicable law, (d) violation of the rights or obligations of a
            third party, (e) negligence or willful misconduct and/or (f) any
            other right that cannot be limited by law.
          </p>

          <p>
            The USER agrees to immediately notify TELEF&Oacute;NICA of the
            existence of any such or similar claim as mentioned above, and to
            cooperate with the TELEF&Oacute;NICA Parties in the defense of such
            Claims. You further agree that the TELEFONICA Parties shall have
            control of the defense or settlement of any Claim. This
            indemnification is in addition to any other indemnification set
            forth in a written agreement between the USER and TELEF&Oacute;NICA.
          </p>

          <p className="mt-1 mb-1 font-bold">10. RELEASE</p>

          <p>
            While TELEF&Oacute;NICA makes its best efforts to ensure that the
            access and use of the Service by the USER is secure,
            TELEF&Oacute;NICA cannot guarantee that the Service, as well as any
            actions of the USER using the Service or the servers of
            TELEF&Oacute;NICA's service providers to enable it, are free of
            viruses or other harmful or malicious components.
          </p>

          <p>
            To the fullest extent permitted by law, the USER agrees that in no
            event shall TELEF&Oacute;NICA or its service providers and/or
            licensors be liable to it or any third party for (a) any loss of
            profits or any indirect or consequential damages arising from these
            General Terms or the Service, products or third-party sites and
            products; (b) unavoidable events due to force majeure; (c) any
            damages related to loss of revenue, loss of production, loss of
            profits, loss of business, loss of anticipated savings, loss of
            opportunity, loss of goodwill, or loss of data, whether direct or
            indirect, and even if foreseeable or if TELEFONICA or its service
            providers had been advised of the possibility of such damages;
            and/or (d) any other Claim, demand or damage of any kind resulting
            from or related to these General Terms arising from: (i) errors of
            the USER, even if inadvertent or without bad faith; (ii) server
            failure or interruption of data processing; (iii) unauthorized
            access or use; (iv) any unauthorized third-party activity,
            including, but not limited to, the use of viruses, phishing, brute
            force, or other means of attack against the Service; (v) any
            vulnerability or any type of flaw, abnormal behavior of the software
            involved, or any other problem that results in loss or injury as a
            result.
          </p>

          <p>
            The Service offered complies with the provisions of the Spanish
            legal system.
          </p>

          <p className="mt-1 mb-1 font-bold">11.SERVICE COMPLIANCE</p>

          <p>
            Access to and use of the Service and the various elements of the
            Service by the USER is at their own direction and risk, and the USER
            is solely responsible for any damage to their computer system or
            mobile device or loss of data that may result therefrom.
          </p>

          <p>
            The USER understands and accepts that the Service is provided by
            TELEF&Oacute;NICA to the USER as described in these General Terms
            and Conditions and on the Service's website. without any major
            guarantees or functionalities additional to those described.
          </p>

          <p>
            TELEF&Oacute;NICA does not provide any guarantee regarding the
            result of the Consultation, without limitation, in terms of its
            accuracy, reliability, veracity or usefulness since, as mentioned
            above, it is an estimate of the use of AI in the Content provided by
            the User, so that the User can carry out a more exhaustive analysis
            on its own about the origin of the content consulted.
          </p>

          <p>
            The USER waives any compensation from TELEF&Oacute;NICA on the
            grounds that the Service: (a) does not correspond to the
            characteristics of any preview of the Service or the statements made
            about it; (b) does not comply with the description and
            functionalities contained in these General Conditions or does not
            meet the expectations of the USER; (c) is not available on an
            uninterrupted, timely, secure, or error-free basis; (d) is not
            accurate, reliable, complete, lawful, or secure; (e) cannot be used
            on any particular device or is available in any particular geography
            or (f) the result of the query to VerifAI is not correct.
          </p>

          <p className="mt-1 mb-1 font-bold">12. THIRD-PARTY CONTENT</p>

          <p>
            The Service may contain links to third-party websites, as well as
            display third-party content, which in no case are under the control
            of TELEF&Oacute;NICA. If the USER accesses this third-party content,
            the USER will be subject to the terms governing such content.
            TELEF&Oacute;NICA is not responsible for such content and does not
            offer any guarantee regarding it.
          </p>

          <p className="mt-1 mb-1 font-bold">13. SERVICE INTERRUPTION</p>

          <p>
            These General Terms and Conditions shall be terminated, with the
            consequent definitive interruption of the Service, for the general
            reasons permitted by law and, in particular, for the following:
          </p>

          <p>By unilateral decision of TELEF&Oacute;NICA.</p>

          <p>
            Due to the USER's failure to comply with the commitments assumed and
            the obligations arising from these General Conditions or other
            applicable conditions.
          </p>

          <p className="mt-1 mb-1 font-bold">14. CESSION</p>

          <p>
            TELEF&Oacute;NICA may assign the Service, and consequently these
            General Conditions, to any Company of the TELEF&Oacute;NICA GROUP
            that may provide the Service in the future.
          </p>

          <p>
            The USER may not transfer or assign these General Terms and
            Conditions, or the rights and licenses granted by TELEF&Oacute;NICA
            under them, without the consent of TELEF&Oacute;NICA.
          </p>

          <p className="mt-1 mb-1 font-bold">
            15. MODIFICATION OF THE GENERAL TERMS AND CONDITIONS
          </p>

          <p>
            TELEF&Oacute;NICA will make, if it deems it necessary, at its sole
            discretion, any change, correction, improvement, or modification in
            the information regarding the object and content of these General
            Conditions without granting the USER any right to claim or indemnity
            or recognition of an obligation.
          </p>

          <p>
            If the USER does not accept the changes made to these General
            Conditions, he/she must stop using the Service.
          </p>

          <p>
            Notwithstanding the foregoing, any modifications to the General
            Terms and Conditions that may be freely made by TELEF&Oacute;NICA
            shall not have retroactive effects to the use of VerifAI by the
            USER, to whom the version of these General Terms and Conditions
            accepted at the time of use of the Service shall apply.
          </p>

          <p className="mt-1 mb-1 font-bold">16. MISCELLANEOUS</p>

          <p>
            If any of the clauses (or part thereof) of these General Conditions
            is declared contrary to equity or law and invalidated by a court or
            other competent authority, such invalidity shall not affect the rest
            of the clauses, which shall remain in force in all their terms.
          </p>

          <p>
            These General Terms and Conditions and all the terms and
            documentation to which they refer constitute the entire agreement
            and undertaking between the USER and TELEF&Oacute;NICA regarding the
            subject matter of these Terms and Conditions.
          </p>

          <p>
            The failure of TELEF&Oacute;NICA to exercise any right or provision
            of these General Terms and Conditions does not constitute a waiver
            of such right or provision.
          </p>

          <p>
            All clauses which, by their nature, must survive the termination of
            the General Terms and Conditions shall continue in full force and
            effect after any termination of these General Conditions by
            TELEF&Oacute;NICA or the USER. Termination shall not limit any
            rights held by TELEFONICA by law.
          </p>

          <p>
            In the event of any contradiction between the Spanish and other
            language versions of the General Conditions, the provisions of the
            General Conditions in Spanish shall prevail. In any case, it will be
            understood that the language of formalization will always be
            Spanish.
          </p>

          <p className="mt-1 mb-1 font-bold">17. CONTACT &amp; COMPLAINTS</p>

          {/* <p>
            The USER may contact TELEF&Oacute;NICA at the following email
            address: <p className="font-bold"> {VERIFAI_MAIL}.</p>{" "}
          </p> */}

          <p>
            The USER may send any request or claim related to the Service and/or
            the result of the Consultation, such as, for example, in the event
            that he/she understands that the estimate provided by VerifAI as a
            result of the Consultation is not adequate and/or in the event that
            he/she wishes to communicate any rights of his/her own and/or third
            party regarding the Content consulted, as well as request
            limitations on VerifAI's use of this (owned) Content.
          </p>

          <p>
            To this end, the support and customer service of the USERS will be
            carried out online through said address and will be attended to as
            soon as possible, during working hours from Monday to Friday,
            provided that it is not a holiday.
          </p>

          <p className="mt-1 mb-1 font-bold">
            18. GOVERNING LAW AND DISPUTE RESOLUTION
          </p>

          <p>
            These General Terms and Conditions shall be governed by Spanish law.
            In the event of a dispute regarding the interpretation or execution
            of these provisions, the parties agree to submit to the Courts and
            Tribunals that correspond to them.
          </p>

          <p>
            In addition, we inform you that the European Commission offers a
            platform for alternative dispute resolution, which the USER can
            access here:
            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=ES.
          </p>
        </div>
      )}
    </div>
  );
};

export default withLanding(ConditionsPage);
