import { Reducer } from "redux";
import { ActionType } from "./constants";
import { Actions, PhotoState } from "./actions";

const initialState: PhotoState = {
  data: {},
};

//Business rules
const reducer: Reducer<PhotoState, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.Photo_SUCESS_DATA:
      //Do something
      return { ...state, loading: false };
    case ActionType.Photo_FAILED_DATA:
      //Do something
      return { ...state, error: "Something error" };
    case ActionType.Photo_LOADING_DATA:
      //Do something
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default reducer;
