import React from "react";

import { t } from "../../utilities/Message";

import "./styles.scss";

const ToolTipInfo = () => {
  return (
    <div className="tooltip_info">
      <ul className="mt-1 mb-1" style={{ listStyle: "none" }}>
        <li style={{ color: "#0096EB" }}>{t("app.toolTipScore.low")}</li>
        <li style={{ color: "#FAAD14" }}>{t("app.toolTipScore.medium")}</li>
        <li style={{ color: "#FF4D4F" }}>{t("app.toolTipScore.big")}</li>
      </ul>
      <p className="text-black">{t("app.toolTipScore.description")}</p>
    </div>
  );
};

export default ToolTipInfo;
