import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";

import TNavigator from "../../components/Navigator";

import "./styles.scss";
import { LanguageContext } from "../../context/i18n/LanguageProvider";

const PrivacyPage = () => {
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="how-it-works">
      <TNavigator page="Política de Privacidad" withSearch={false} />
      {locale === "es" ? (
        <div className="max-width how_content">
          <p className="mt-1">
            Telefónica Innovación Digital, S.L.U., como titular de este sitio
            web, te informa de que las empresas del Grupo Telefónica tienen el
            compromiso de respetar la privacidad de los usuarios y el secreto y
            seguridad de los datos personales, en cumplimiento de lo establecido
            en el Reglamento (UE) 2016/679, Reglamento General de Protección de
            Datos.
          </p>

          <p>Nuestros principios</p>

          <p>Transparencia</p>

          <p>
            No vamos a tratar tus datos de una forma inesperada, oscura o
            abusiva.
          </p>

          <p>
            Cuando recabemos tus datos personales a través de nuestra web serás
            informado convenientemente sobre: quién trata tus datos, cuál es la
            finalidad del tratamiento, en qué se basa la legitimidad del
            tratamiento, la posibilidad de ejercer tus derechos y otra
            información de interés. Además, tus datos serán cancelados según la
            política de retención definida sobre la cuál serás informado y en
            todo caso, cuando lo solicites ejerciendo el derecho de cancelación.
          </p>

          <p>Control</p>

          <p>
            Recabaremos tu consentimiento siempre que sea necesario y pondremos
            a tu disposición las herramientas necesarias para que puedas acceder
            y actualizar tu información personal, así como decidir cómo
            gestionar tus datos, seleccionando las finalidades para las que los
            vamos a tratar.
          </p>

          <p>Seguridad</p>

          <p>
            Nos preocupamos por garantizar la seguridad, el secreto y la
            confidencialidad de tus datos. Por eso, como parte de nuestro
            compromiso y en cumplimiento de la legislación vigente, hemos
            adoptado las más exigentes y robustas medidas de seguridad y medios
            técnicos para evitar su pérdida, mal uso o su acceso sin tu
            autorización, comprometiéndonos a guardar secreto respecto de los
            mismos y garantizando el deber de guardarlos adoptando todas las
            medidas necesarias y razonables que eviten su alteración, pérdida y
            tratamiento o acceso no autorizado, de acuerdo con lo establecido en
            la legislación aplicable.
          </p>

          <p>
            Cualquier duda que pueda surgirte de esta Política de Privacidad,
            contáctanos en: {" "}
            <strong className="font-bold">cdo-gdpr@telefonica.com</strong>  y
            estaremos encantados de poder ayudarte.
          </p>

          <p>
            1. &iquest;Qui&eacute;n es responsable del tratamiento de tus datos?
          </p>

          <p>
            La compa&ntilde;&iacute;a responsable del tratamiento de tus datos
            es:
          </p>

          <p>
            -Denominación social: Telefónica Innovación Digital, S.L.U. (en
            adelante, “Telefónica Innovación Digital” o “nosotros”).{" "}
          </p>

          <p>- NIF: B83188953</p>

          <p>
            Domicilio social: Ronda de la Comunicación, s/n, Edificio Central,
            28050, Madrid.
          </p>
          <p>
            Contacto Delegado de Protección de Datos:
            DPO_telefonicasa@telefonica.com{" "}
          </p>

          <p>2. &iquest;Qu&eacute; datos tratamos sobre ti?</p>
          <p>
            Primeramente, te informamos que el servicio VerfiAI no identifica a
            sus usuarios. Conforme mencionamos en las Condiciones Generales de
            VerifAI, no es necesaria la creación de una cuenta de usuario para
            utilizar el servicio y tampoco utilizamos cookies en nuestra página
            web, por lo que todo el tratamiento de los datos personales se
            realiza sin la necesidad de identificar a los usuarios o
            interesados. Por lo tanto, sólo trataremos los datos mínimos y
            necesarios para poder operar la página web de VerifAI y ofrecerte el
            servicio, como son los datos de conexión de la web con tu
            dispositivo, así como para almacenar los registros de aceptación de
            las Condiciones Generales de VerifAI. La única excepción será el
            tratamiento de datos personales de aquellas personas que nos
            contacten de forma directa y voluntaria a través de los canales
            habilitados al efecto. En este caso, trataremos los datos básicos,
            identificativos y de contacto, que nos proporcionen. Por otro lado,
            es posible que los usuarios usen VerifAI y nos faciliten contenidos
            incorporando datos personales de terceras personas ajenas al
            servicio (p.ej. imágenes).{" "}
          </p>

          <p>
            3. &iquest;C&oacute;mo obtenemos y de d&oacute;nde proceden tus
            datos?
          </p>

          <p>
            Para usuarios de VerifAI: Directamente de ti cuando (1) utilices el
            servicio de VerifAI; y/o (2) te pongas en contacto con nosotros a
            través de los medios disponibles en nuestra página web. Por ejemplo,
            a través del buzón de soporte del servicio o cuando rellenes
            información personal en cualquier formulario disponible en nuestra
            página web. Para terceros: De los usuarios, en caso de que usen
            VerifAI con contenidos que incorporen sus datos personales.
          </p>

          <p>
            4. &iquest;Para qu&eacute; y por qu&eacute; utilizamos tus datos?
          </p>

          <p>
            Telef&oacute;nica Digital tratar&aacute; los datos para las
            siguientes finalidades:
          </p>

          <p>i. Operar y prestarte el servicio de VerifAI.</p>

          <p>
            ii. Registrar la aceptaci&oacute;n de las Condiciones Generales de
            VerifAI, para el ejercicio o defensa de reclamaciones.
          </p>

          <p>
            iii. Responder a las consultas recibidas a trav&eacute;s de nuestros
            canales de contacto: en el caso de que nos contactes, trataremos tus
            datos de contacto y aquellos datos personales que proporciones, con
            la finalidad de gestionar y contestar a tu consulta.
          </p>

          <p>5. &iquest;Durante cu&aacute;nto tiempo conservamos tus datos?</p>

          <p>
            Trataremos tus datos mientras sean necesarios para las finalidades
            descritas:
          </p>

          <p>
            i. Respecto a operaci&oacute;n y prestaci&oacute;n del servicio de
            VerifAI: trataremos los datos durante el tiempo que el usuario tenga
            una sesi&oacute;n abierta en la p&aacute;gina web de VerifAI y/o
            mientras dure la prestaci&oacute;n del servicio.
          </p>

          <p>
            ii. Respecto al registro de la aceptaci&oacute;n de las Condiciones
            Generales de VerifAI: dichos registros se conservar&aacute;n hasta
            finalizar el plazo de caducidad de las acciones legales que, en su
            caso, se puedan llevar a cabo por o contra Telef&oacute;nica.
          </p>

          <p>
            iii. Respecto a las consultas recibidas a trav&eacute;s de canales
            de contacto: aquellos datos recabados o tratados a trav&eacute;s de
            esos canales se conservar&aacute;n s&oacute;lo durante el tiempo
            necesario para responder la consulta, y, en su caso, finalizar el
            plazo de caducidad de las acciones legales que se puedan llevar a
            cabo por o contra Telef&oacute;nica, relacionadas con dichas
            consultas.
          </p>

          <p>
            Transcurridos estos plazos, los datos podr&aacute;n ser destruidos,
            bloqueados y/o anonimizados, seg&uacute;n proceda, y de conformidad
            con lo indicado por la legislaci&oacute;n.
          </p>

          <p>6. &iquest;Qui&eacute;n tiene acceso a tus datos?</p>

          <p>
            Solo accede a tus datos Telefónica Innovación Digital y aquellos
            subcontratistas que actúen por nuestra cuenta y nombre, en calidad
            de encargados de tratamiento, sujetos contractualmente a nuestras
            instrucciones, únicamente con las finalidades lícitas informadas y
            durante el periodo de tiempo estrictamente necesario para ello. En
            caso de que vayamos a compartir tu información con terceros en
            calidad de responsables del tratamiento (p.ej. otras empresas del
            Grupo al que pertenecemos que vayan a tratar tus datos con
            finalidades propias diferentes de las informadas) te informaremos
            adecuadamente y, en el caso de ser necesario, recogeremos las
            autorizaciones necesarias para hacerlo.
          </p>

          <p>
            7. &iquest;Cu&aacute;les son tus derechos y c&oacute;mo puedes
            controlar tus datos?
          </p>

          <p>
            En el caso de que contactes con nosotros a trav&eacute;s de los
            medios disponibles y/o rellenes un formulario de comunicaciones en
            nuestra p&aacute;gina web, podr&aacute;s ejercer los siguientes
            derechos de protecci&oacute;n de datos frente a Telef&oacute;nica
            Digital seg&uacute;n sean de aplicaci&oacute;n:
          </p>

          <p>
            - Derecho de acceso: derecho a solicitar el detalle de los datos que
            tenemos sobre ti y c&oacute;mo los tratamos, as&iacute; como la
            obtenci&oacute;n de una copia de los mismos.
          </p>

          <p>
            - Derecho de rectificaci&oacute;n: derecho a obtener la
            rectificaci&oacute;n de tus datos inexactos o err&oacute;neos,
            as&iacute; como a completar aquellos otros que sean incompletos.
          </p>

          <p>
            - Derecho de supresi&oacute;n: derecho a solicitar la
            eliminaci&oacute;n o supresi&oacute;n de tus datos e
            informaci&oacute;n en determinadas circunstancias. No obstante, ten
            en cuenta de que hay determinadas ocasiones en las que estamos
            legalmente legitimados para continuar
          </p>

          <p>
            conservando y tratando tus datos (p.ej. para el cumplimiento de una
            obligaci&oacute;n legal de conservaci&oacute;n de datos).
          </p>

          <p>
            - Derecho de limitaci&oacute;n: derecho a restringir o limitar el
            tratamiento de tus datos en determinadas circunstancias (p.ej. si
            aplica la supresi&oacute;n de datos pero, en vez de eliminarlos,
            prefieres que los bloqueemos y los tratemos &uacute;nicamente a los
            efectos de su conservaci&oacute;n ya que vas a necesitarlos
            posteriormente para interponer una reclamaci&oacute;n). De nuevo,
            ten en cuenta que puede haber ocasiones en que estemos legalmente
            legitimados para rechazar tu solicitud de limitaci&oacute;n.
          </p>

          <p>
            - Derecho de oposici&oacute;n: derecho a oponerte a que tratemos tus
            datos para una finalidad concreta, en determinadas circunstancias
            previstas en la normativa y relacionadas con tu situaci&oacute;n
            personal. En algunos casos te facilitamos opciones sencillas para
            oponerte directamente a trav&eacute;s de la web, app o e-mail (p.ej.
            oposici&oacute;n a recibir comunicaciones comerciales remitidas) y
            en otros casos deber&aacute;s contactar con nosotros a trav&eacute;s
            del e-mail para que nuestro equipo de privacidad pueda evaluar tu
            solicitud con m&aacute;s detalle.
          </p>

          <p>
            - Derecho de portabilidad de datos: derecho a recibir tus datos en
            un formato de uso com&uacute;n que te permita portarlos a otra
            empresa, as&iacute; como, en su caso, a solicitarnos la
            transmisi&oacute;n directa de los mismos a dicha otra empresa cuando
            sea t&eacute;cnicamente posible. Recuerda que el derecho a la
            portabilidad de datos es distinto al derecho a la portabilidad de tu
            n&uacute;mero de tel&eacute;fono.
          </p>

          <p>
            - Derecho a no ser objeto a decisiones automatizadas: derecho a
            oponerte a ser objeto de una decisi&oacute;n basada
            &uacute;nicamente en el tratamiento automatizado de datos (sin
            intervenci&oacute;n humana), incluida la elaboraci&oacute;n de
            perfiles, que produzca efectos jur&iacute;dicos en ti o te afecte de
            una forma significativa.
          </p>

          <p>
            - Derecho a retirar el consentimiento en cualquier momento: derecho
            a retirar tu consentimiento en cualquier momento al tratamiento de
            tus datos para una finalidad concreta, cuando est&eacute; basada en
            dicha base legitimadora
          </p>

          <p>
            Con car&aacute;cter general, podr&aacute;s ejercitar estos derechos
            (en cualquier momento y de forma gratuita) a trav&eacute;s de
            <strong className="font-bold"> cdo-gdpr@telefonica.com</strong>
          </p>

          <p>
            Para ello es importante tener en cuenta que cuando ejercites un
            derecho, en la mayor&iacute;a de los casos, deber&aacute;s
            especificar con claridad cu&aacute;l ejercitas y aportar una copia
            de tu documento oficial de identidad (p.ej. DNI, NIE o Pasaporte) o
            cualquier otra informaci&oacute;n que nos permita identificarte,
            as&iacute; como, si es posible, env&iacute;es tu solicitud desde el
            mismo correo electr&oacute;nico que nos facilitaste inicialmente.
          </p>

          <p>
            Por &uacute;ltimo, en caso de que no estar de acuerdo en la manera
            de tratar tus datos tendr&aacute;s derecho a presentar
            reclamaci&oacute;n ante la autoridad nacional de control,
            dirigi&eacute;ndote a la Agencia Espa&ntilde;ola de
            Protecci&oacute;n de Datos, cuyos datos de contacto son los
            siguientes:
          </p>

          <p>Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos</p>

          <p>C/ Jorge Juan, 6 &ndash; 28001 Madrid www.aepd.es</p>

          <p>
            Con car&aacute;cter previo, y para cualquier duda, queja o
            reclamaci&oacute;n sobre el tratamiento de tus datos por
            Telef&oacute;nica Digital, podr&aacute;s ponerte en contacto con
            nuestro Delegado de Protecci&oacute;n de Datos escribiendo a
            DPO_telefonicasa@telefonica.com.
          </p>

          <p>8. &iquest;C&oacute;mo protegemos tus datos?</p>

          <p>
            Telefónica Innovación Digital garantiza la seguridad, el secreto y
            la confidencialidad de tus datos, comunicaciones e información
            personal adoptado las más exigentes y robustas medidas de seguridad,
            y poniendo a tu disposición herramientas y funcionalidades que te
            permiten controlarlos.
          </p>
        </div>
      ) : (
        <div className="max-width how_content">
          <p className="mt-1">
            Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U., as the owner of
            this website, informs you that the companies of the
            Telef&oacute;nica Group are committed to respecting the privacy of
            users and the secrecy and security of personal data, in compliance
            with the provisions of Regulation (EU) 2016/679, General Data
            Protection Regulation.
          </p>

          <p>Our Principles</p>

          <p>Transparency</p>

          <p>
            We will not treat your data in an unexpected, obscure, or abusive
            way.
          </p>

          <p>
            When we collect your personal data through our website, you will be
            duly informed about: who processes your data, what is the purpose of
            the processing, what is the basis of the legitimacy of the
            processing, the possibility of exercising your rights and other
            information of interest. In addition, your data will be cancelled
            according to the defined retention policy about which you will be
            informed and, in any case, when you request it by exercising the
            right of cancellation.
          </p>

          <p>Control</p>

          <p>
            We will obtain your consent whenever necessary and we will provide
            you with the necessary tools so that you can access and update your
            personal information, as well as decide how to manage your data,
            selecting the purposes for which we are going to process it.
          </p>

          <p>Safety</p>

          <p>
            We care about ensuring the security, secrecy and confidentiality of
            your data. For this reason, as part of our commitment and in
            compliance with current legislation, we have adopted the most
            demanding and robust security measures and technical means to
            prevent their loss, misuse or access without your authorization,
            committing ourselves to maintain secrecy with respect to them and
            guaranteeing the duty to keep them by adopting all the necessary and
            reasonable measures to prevent their alteration. loss and
            unauthorized processing or access, in accordance with applicable
            law. If you have any questions about this Privacy Policy, please
            contact us at: cdo-gdpr@telefonica.com and we will be happy to help
            you.
          </p>

          <p></p>

          <p>Who is responsible for the processing of your data?</p>

          <p>The company responsible for processing your data is:</p>

          <p>
            {" "}
            <strong className="font-bold">cdo-gdpr@telefonica.com</strong>{" "}
          </p>

          <p>
            Company name: Telef&oacute;nica Innovaci&oacute;n Digital, S.L.U.
            (hereinafter, &quot;Telef&oacute;nica Innovaci&oacute;n
            Digital&quot; or &quot;we&quot;).
          </p>

          <p>NIF: B83188953</p>

          <p>
            Registered office: Ronda de la Comunicaci&oacute;n, s/n, Edificio
            Central, 28050, Madrid.
          </p>

          <p>
            Contact Data Protection Officer: DPO_telefonicasa@telefonica.com
          </p>

          <p></p>

          <p>What data do we process about you?</p>

          <p>
            First of all, we inform you that the VerfiAI service does not
            identify its users. As mentioned in VerifAI's General Terms and
            Conditions, it is not necessary to create a user account to use the
            service and we do not use cookies on our website, so all the
            processing of personal data is carried out without the need to
            identify users or interested parties.
          </p>

          <p>
            Therefore, we will only process the minimum data necessary to be
            able to operate VerifAI website and offer you the service, such as
            the connection data of the website with your device, as well as to
            store the records of acceptance of the VerifAI General Conditions.
          </p>

          <p>
            The only exception will be the processing of personal data of those
            people who contact us directly and voluntarily through the channels
            provided for this purpose. In this case, we will process the basic
            identification and contact data that you provide to us.
          </p>

          <p>
            On the other hand, it is possible for users to use VerifAI and
            provide us with content incorporating personal data of third parties
            outside the service (e.g. images).
          </p>

          <p>How do we collect and where does your data come from?</p>

          <p>
            For VerifAI users: Directly from you when you (1) use the VerifAI
            service; and/or (2) contact us through the means available on our
            website. For example, through the service's support mailbox or when
            you fill in personal information on any form available on our
            website.
          </p>

          <p>
            For third parties: From users, in case they use VerifAI with content
            that incorporates their personal data.
          </p>

          <p>What and why do we use your data?</p>

          <p>
            Telef&oacute;nica Innovaci&oacute;n Digital will process the data
            for the following purposes:
          </p>

          <p>Operate and provide you with the VerifAI service.</p>

          <p></p>

          <p>
            Register the acceptance of VerifAI's General Terms and Conditions,
            for the exercise or defense of claims.
          </p>

          <p></p>

          <p>
            Respond to queries received through our contact channels: if you
            contact us, we will process your contact details and the personal
            data you provide, in order to manage and respond to your query.
          </p>

          <p>How long do we keep your data?</p>

          <p>
            We will process your data for as long as it is necessary for the
            purposes described:
          </p>

          <p>
            Regarding the operation and provision of VerifAI's service: we will
            process the data during the time that the user is logged in to the
            VerifAI website and/or for the duration of the provision of the
            service.
          </p>

          <p></p>

          <p>
            Regarding the record of acceptance of VerifAI's General Terms and
            Conditions: these records will be kept until the end of the expiry
            period of the legal actions that, if applicable, may be carried out
            by or against Telef&oacute;nica.
          </p>

          <p></p>

          <p>
            Regarding queries received through contact channels: the data
            collected or processed through these channels will be kept only for
            the time necessary to respond to the query, and, where appropriate,
            to end the expiry period of any legal actions that may be carried
            out by or against Telef&oacute;nica, related to such queries.
          </p>

          <p>
            After these periods, the data may be destroyed, blocked and/or
            anonymised, as appropriate, and in accordance with the provisions of
            the law.
          </p>

          <p>Who has access to your data?</p>

          <p>
            Your data will only be accessed by Telef&oacute;nica
            Innovaci&oacute;n Digital and those subcontractors acting on our
            behalf, as data processors, contractually subject to our
            instructions, only for the lawful purposes reported and for the
            period strictly necessary for this purpose.
          </p>

          <p>
            In the event that we are going to share your information with third
            parties in their capacity as data controllers (e.g. other companies
            of the Group to which we belong that are going to process your data
            for their own purposes other than those informed) we will inform you
            adequately and, if necessary, we will collect the necessary
            authorizations to do so.
          </p>

          <p>What are your rights and how can you control your data?</p>

          <p>
            If you contact us through the means available and/or fill out a
            communications form on our website, you may exercise the following
            data protection rights against Telef&oacute;nica Innovaci&oacute;n
            Digital as applicable:
          </p>

          <p></p>

          <p>
            Right of access: the right to request details of the data we hold
            about you and how we process it, as well as to obtain a copy of it.
          </p>

          <p>
            Right to rectification: the right to obtain the rectification of
            your inaccurate or erroneous data, as well as to complete any other
            data that is incomplete.
          </p>

          <p>
            Right to erasure: the right to request the deletion or deletion of
            your data and information in certain circumstances. Please note,
            however, that there are certain occasions when we are legally
            entitled to continue to retain and process your data (e.g. for
            compliance with a legal data retention obligation).
          </p>

          <p>
            Right to restriction: the right to restrict or restrict the
            processing of your data in certain circumstances (e.g. if you apply
            to the erasure of data but, instead of deleting it, you prefer that
            we block it and process it only for the purposes of its retention,
            as you will need it later to lodge a claim). Again, please note that
            there may be times when we are legally entitled to deny your request
            for limitation.
          </p>

          <p>
            Right to object: the right to object to the processing of your data
            for a specific purpose, in certain circumstances provided for in the
            regulations and related to your personal situation. In some cases,
            we provide you with simple options to object directly through the
            website, app or e-mail (e.g. objection to receiving commercial
            communications sent) and in other cases you will need to contact us
            via e-mail so that our privacy team can evaluate your request in
            more detail.
          </p>

          <p>
            Right to portability: the right to receive your data in a commonly
            used format that allows you to port it to another company, as well
            as, where appropriate, to request the direct transmission of your
            data to that other company when technically possible. Please note
            that the right to data portability is different from the right to
            portability of your phone number.
          </p>

          <p>
            Right not to be subject to automated individual decisions: the right
            to object to being subject to a decision based solely on automated
            data processing (without human intervention), including profiling,
            that produces legal effects on you or significantly affects you.
          </p>

          <p>
            Right to withdraw consent at any time: the right to withdraw your
            consent at any time to the processing of your data for a specific
            purpose, where it is based on that legitimate basis.
          </p>

          <p></p>

          <p>
            In general, you can exercise these rights (at any time and free of
            charge) through cdo-gdpr@telefonica.com.
          </p>

          <p></p>

          <p>
            To do this, it is important to bear in mind that when you exercise a
            right, in most cases, you must clearly specify which one you are
            exercising and provide a copy of your official identity document
            (e.g. DNI, NIE or Passport) or any other information that allows us
            to identify you, as well as, if possible, send your request from the
            same email address you initially provided.
          </p>

          <p></p>

          <p>
            Finally, if you do not agree with the way your data is processed,
            you will have the right to file a complaint with the national
            supervisory authority, by contacting the Spanish Data Protection
            Agency, whose contact details are as follows:
          </p>

          <p>Spanish Data Protection Agency</p>

          <p>C/ Jorge Juan, 6 &ndash; 28001 Madrid</p>

          <p>www.aepd.es</p>

          <p></p>

          <p>
            Previously, and for any doubts, complaints or claims regarding the
            processing of your data by Telef&oacute;nica Innovaci&oacute;n
            Digital, you may contact our Data Protection Officer by writing to
            DPO_telefonicasa@telefonica.com.
          </p>

          <p></p>

          <p>How do we protect your data?</p>

          <p>
            Telef&oacute;nica Digital Innovation guarantees the security,
            secrecy and confidentiality of your data, communications and
            personal information by adopting the most demanding and robust
            security measures, and providing you with tools and functionalities
            that allow you to control them.
          </p>
        </div>
      )}
    </div>
  );
};

export default withLanding(PrivacyPage);
