import { FormattedMessage } from "react-intl";

export const CONTENT_PAGE = [
  {
    id: 1,
    title: "app.HowItWorks.Index.Link_1",
    content: [
      {
        id: 1,
        img: "images/blink.gif",
        text: (
          <>
            <h5 className="mb-0">
              <FormattedMessage id="app.HowItWorks.Link_1.Subtitle" />
            </h5>
            <p className="mt-0">
              <FormattedMessage id="app.HowItWorks.Link_1.deepfakeDetection" />
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "app.HowItWorks.Index.Link_2",
    content: [
      {
        id: 1,
        img: "images/carousel/how_to_image.png",
        text: (
          <>
            <p>
              {" "}
              <FormattedMessage id="app.HowItWorks.Link_2.huggingFaceModel" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.HowItWorks.Link_2.swinTransformer" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.HowItWorks.Link_2.trainImages" />
            </p>
            <p className="mt-1">
              <FormattedMessage id="app.HowItWorks.Link_2.moreDetails" />
            </p>
            <a href="https://medium.com/@matthewmaybe/can-an-ai-learn-to-identify-ai-art-545d9d6af226">
              <FormattedMessage id="app.HowItWorks.Link_2.mediumLink" />
            </a>
          </>
        ),
      },
    ],
  },
];
