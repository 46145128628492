import React, { useState, FC, ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";

import { LANG_DEFAULT, langAvailable } from "../../configuration/config";
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import ENMessages from "./../../lang/en.json";
import ESMessages from "./../../lang/es.json";

type ProviderProps = {
  children: ReactNode;
};

export const LanguageContext = React.createContext<{
  locale: string;
  setLocale: (locale: string) => void;
}>({ locale: LANG_DEFAULT, setLocale: () => {} });

export const LanguageProvider: FC<ProviderProps> = ({ children }: any) => {
  const [locale, setLocale] = useState<string>(LANG_DEFAULT);
  const [messages, setMessages] = useState(
    locale === LANG_DEFAULT ? ESMessages : ENMessages
  );

  const selectLanguage = (value: string) => {
    const updateLang = value;
    localStorage.setItem("locale", updateLang);
    setLocale(updateLang);

    if (updateLang === langAvailable.English) {
      setMessages(ENMessages);
    } else {
      setMessages(ESMessages);
    }
  };

  return React.createElement(
    ConfigProvider,
    { locale: locale === langAvailable.English ? enUS : esES },
    React.createElement(
      IntlProvider,
      { messages: messages, locale: locale },
      React.createElement(
        LanguageContext.Provider,
        { value: { locale, setLocale: selectLanguage } },
        children
      )
    )
  );
};
