import { Button, Progress, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { t } from "../../../../utilities/Message";
import ToolTipInfo from "../../../ToolTipInfo";
import { SearchOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import { useHistory } from "react-router-dom";
import { ROUTES_GENERAL } from "../../../../utilities/Constant";
import MetaData from "../Metadata";
import TProgressBar from "../../../ProgressBar";
import { FilesAvailable } from "../../../../../domain/entities/Api";
import { STATUS_TAKS_NUMBER } from "../../../../utilities/Function";
import {
  file_analyzed,
  user_interaction_other,
} from "../../../../hooks/useAnalysis";

const FreeContent = (props) => {
  const [loadingClipboard, setLoadingClipboard] = useState(false);
  const history = useHistory();
  const [messageApi, contextHolder] = message.useMessage();

  const handleCopy = async () => {
    try {
      setLoadingClipboard(true);
      const contentElement = document.getElementById("result");
      const canvas = await html2canvas(contentElement, {
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      canvas.toBlob(
        (blob) => {
          navigator.clipboard.write([
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]);
        },
        "image/png",
        1
      );
      messageApi.open({
        type: "success",
        content: "Copiado",
      });
    } catch (error) {
      console.error("Error al generar y copiar la imagen:", error);
    } finally {
      setLoadingClipboard(false);
    }
  };

  const _onClick = () => {
    user_interaction_other(props.typeFile, {
      ai_probability: Number(percentage / 100),
    });

    history.push(ROUTES_GENERAL.HOME_PAGE);
  };

  const {
    percentage,
    color,
    className,
    title,
    content,
    result,
    loading,
    typeFile,
    onCancel,
    status,
  } = props;

  useEffect(() => {
    if (status === STATUS_TAKS_NUMBER.finished) {
      file_analyzed(
        typeFile === FilesAvailable.audio ? "audio_analysis" : "image_analysis",
        typeFile,
        Number(percentage) / 100
      );
    }
  }, [status, typeFile]);

  return (
    <div className="result__body">
      {contextHolder}

      <div className="result__body-content">
        <MetaData typeFile={typeFile} loading={loading} />
      </div>

      <div className="result__body-info">
        {loading || status !== STATUS_TAKS_NUMBER.finished ? (
          <div className="text-center">
            <TProgressBar />
            {typeFile === FilesAvailable.video ? (
              <p className="mt-1 font-medium-2">
                Estamos calculado el importe del análisis del video
              </p>
            ) : (
              <p className="mt-1 font-medium-2">{t("app.result.loading")}</p>
            )}
            <p className="">{t("app.result.loading_2")}</p>
            <div className="mt-2 full-center d-flex ">
              <Button
                className="text-center"
                style={{ display: "flex" }}
                size="large"
                type="primary"
                onClick={onCancel}
              >
                Cancelar
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex g-20">
              <Progress
                type="circle"
                percent={Number(percentage)}
                size={160}
                strokeColor={color}
                strokeWidth={5}
                className={className}
              />
              <div className="m-auto">
                <p
                  className="blue"
                  style={{ maxWidth: "200px", marginBottom: "20px" }}
                >
                  {title && t(title)}
                  <br />
                  <strong className="uppercase font-bold">
                    {content && t(content)}
                  </strong>{" "}
                  <br />
                  {result && t(result)}
                </p>
                {loadingClipboard ? (
                  <Spin />
                ) : (
                  <p onClick={handleCopy} className="pointer underline blue-2">
                    Copiar al portapeles
                  </p>
                )}
              </div>
            </div>
            <ToolTipInfo />

            <div className="mt-2">
              <Button
                size="large"
                className=" btn-more"
                icon={<SearchOutlined />}
                type="primary"
                onClick={_onClick}
              >
                {t("app.result.more")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FreeContent;
