import React, { useContext, useEffect } from "react";
import { withLanding } from "../../hocs/withLanding";
import { page_view, user_interaction_help } from "../../hooks/usePageView";
import { EVENTS_TGM } from "../../utilities/Events";
import { LanguageContext } from "../../context/i18n/LanguageProvider";
import { Tabs } from "antd";
import { TABS_INFO } from "./Constants"; // Importamos la info de las pestañas

import "./styles.scss";

const { TabPane } = Tabs;

const HelpPage = () => {
  const { locale } = useContext(LanguageContext);

  const _onClick = (index) => {
    const indexSelected = TABS_INFO.find((tab) => tab.key === index);
    user_interaction_help(indexSelected.tab);
  };
  useEffect(() => {
    page_view({ page_type: EVENTS_TGM.help, locale });
  }, []);

  return (
    <div className="help-page">
      <div className="max-width help_content">
        <h1 className="text-center mt-2 mb-3">Ayuda</h1>
        <Tabs defaultActiveKey="1" onChange={_onClick}>
          {TABS_INFO.map(({ key, tab, description }) => (
            <TabPane tab={tab} key={key}>
              <div className="mt-2">{description()}</div>
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default withLanding(HelpPage);
