import React, { ReactNode } from "react";

import "./style.scss";

// Define your props here

interface ContentBody {
  text?: ReactNode;
  subTitle?: string;
  img?: string;
  altImg?: string;
  styles?: any;
  multipleImg?: string[];
}

interface ContentProps {
  className?: string;
  content?: ContentBody[];
}

const TContent = (props: ContentProps) => {
  return (
    <div className={`Content ${props.className}`}>
      {props?.content.map((content) => (
        <>
          {content?.multipleImg && (
            <div className="img-multiple" style={content?.styles}>
              {" "}
              {content?.multipleImg.map((img) => (
                <img src={img} alt="" />
              ))}
            </div>
          )}
          {content?.img && (
            <img
              src={content.img}
              alt={content.altImg}
              style={content?.styles}
            />
          )}
          <div className="Content__body">
            {content.subTitle && <h5 className="mb-0"> {content.subTitle}</h5>}
            {content.text && <p>{content.text}</p>}
          </div>
        </>
      ))}
    </div>
  );
};

TContent.displayName = "TContent";

TContent.defaultProps = {
  className: "",
  content: [
    {
      id: 1,
      text: "lorem",
      img: "https://via.placeholder.com/250x350",
    },
  ],
};

export default TContent;
