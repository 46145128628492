import { Container } from "inversify";
import "reflect-metadata";

import { IMAGE_PORT, ImagePort } from "../domain/port/inbound/ImagePort";
import { PAYMENT_PORT, PaymentPort } from "../domain/port/inbound/PaymentPort";

import { ImageAdapter } from "../infraestructure/adapters/ImageAdapter";
import { GTMPort, GTM_PORT } from "../domain/port/inbound/GTMPort";
import { GTMAdapter } from "../infraestructure/adapters/GTMAdapter";
import { PaymentAdapter } from "../infraestructure/adapters/PaymentAdapter";

let container = new Container();

//Port to Image
const bindPorts = () => {
  container.bind<ImagePort>(IMAGE_PORT).to(ImageAdapter);
  container.bind<GTMPort>(GTM_PORT).to(GTMAdapter);
  container.bind<PaymentPort>(PAYMENT_PORT).to(PaymentAdapter);
};

bindPorts();

export { container };
