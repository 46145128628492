import { injectable } from "inversify";
import { GTMPort } from "../../domain/port/inbound/GTMPort";

@injectable()
export class GTMAdapter implements GTMPort {
  constructor() {}

  init(): void {
    console.log("GTM initialized");
  }

  emitEvent(eventName: string, eventData: any): void {
    console.log("GTMPort -> emitEvent -> eventName", eventName, eventData);
    if (window["dataLayer"]) {
      window["dataLayer"].push({
        event: eventName,
        ...eventData,
      });
    }
  }
}
